import axios from 'axios'
import { removeItemFromStorage } from '../utils/helpers/localStorageHelpers'
import { baseURL, JWT_TOKEN_KEY } from '../utils/constants/general'
import index from '../store'
// eslint-disable-next-line import/no-cycle
import { logOut } from '../store/user/userActions'

const headers = {
   'Content-Type': 'application/json',
}

const axiosInstance = axios.create({
   baseURL,
   headers,
})

axiosInstance.interceptors.request.use(
   (config) => {
      const updatedConfig = { ...config }
      const {
         user: { accessToken },
      } = index.getState()
      if (accessToken)
         updatedConfig.headers.Authorization = `Bearer ${accessToken}`

      return updatedConfig
   },
   (error) => {
      return Promise.reject(error)
   }
)

axiosInstance.interceptors.response.use(
   (response) => {
      return Promise.resolve(response)
   },
   (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
         removeItemFromStorage(JWT_TOKEN_KEY)
         index.dispatch(logOut())
         // throw new Error('401 unauthotized'
         window.location.reload()
         return null
      }
      return Promise.reject(error)
   }
)

export default axiosInstance
