import { useEffect, useState } from 'react'

const useCheckMobileScreen = (screen = 500) => {
   const [width, setWidth] = useState(window.outerWidth)
   const handleWindowSizeChange = () => {
      setWidth(window.outerWidth)
   }

   useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
         window.removeEventListener('resize', handleWindowSizeChange)
      }
   }, [])

   return width <= screen
}

export default useCheckMobileScreen
