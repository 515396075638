import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   isLoading: false,
   regions: [],
}

const regionSlice = createSlice({
   name: 'regionSlice',
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      getRegions(state, { payload }) {
         state.regions = payload.regions
         state.isLoading = false
      },
   },
})

export const regionActions = regionSlice.actions

export default regionSlice
