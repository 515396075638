import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useDispatch } from 'react-redux'
import Logout from '../../assets/icons/Logout.svg'
import ConfirmModal from '../../components/UI/ConfirmModal'
import checkIcon from '../../assets/icons/checkIcon.svg'
import { ReactComponent as MenuBurgerIcon } from '../../assets/icons/MenuBurger.svg'
import { ReactComponent as ToggleCouriersDrawerIcon } from '../../assets/icons/ToggleCourierDrawerIcon.svg'
import { logOut } from '../../store/user/userActions'
import { ROLES } from '../../utils/constants/general'
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen'
import MobileDrawer from '../SideDrawer/MobileDrawer'
import MobileDrawerForCouries from '../SideDrawerForCouriers/MobileDrawerForCouriers'

const Navigation = ({ role, officeUserData }) => {
   const dispatch = useDispatch()
   const [anchorEl, setAnchorEl] = useState(null)
   const isMobileScreen = useCheckMobileScreen()

   const [isMobileDrawerOpened, setIsMobileDrawerOpened] = useState(false)
   const [isCourierDrawerForModalIsOpened, setIsCourierDrawerForModalIsOpened] =
      useState(false)
   const open = Boolean(anchorEl)

   const [openWindow, setOpenWindow] = useState(false)
   const handleOpenWindow = () => setOpenWindow(true)
   const handleCloseWindow = () => setOpenWindow(false)

   const handleClick = (event) => setAnchorEl(event.currentTarget)

   const handleClose = () => setAnchorEl(null)

   const confirmFunction = () => {
      dispatch(logOut())
      handleCloseWindow()
   }

   const userRole = useMemo(() => {
      if (role === ROLES.ROLE_HOST) {
         return (
            <NavMenu>
               <Tooltip title="Account settings" placement="bottom-end">
                  <StyledIConButton onClick={handleClick} size="small">
                     <StyledAvatar />
                     {!isMobileScreen && (
                        <StyledTypography>
                           Administrator
                           <img src={checkIcon} alt="checkIcon" />
                        </StyledTypography>
                     )}
                  </StyledIConButton>
               </Tooltip>
            </NavMenu>
         )
      }

      if (role === ROLES.ROLE_MASTER && isMobileScreen) {
         return (
            <IconButton
               onClick={() => setIsCourierDrawerForModalIsOpened(true)}
            >
               <ToggleCouriersDrawerIcon />
            </IconButton>
         )
      }

      return null
   }, [role])

   return (
      <MainContainer>
         <Container>
            {isMobileScreen && (
               <StyledBurgerIconContainer>
                  <IconButton onClick={() => setIsMobileDrawerOpened(true)}>
                     <MenuBurgerIcon />
                  </IconButton>
               </StyledBurgerIconContainer>
            )}

            {role === ROLES.ROLE_MASTER && (
               <Block>
                  <div>
                     Company:
                     <StyleTitle>{officeUserData.companyName}</StyleTitle>
                  </div>
                  <div>
                     Branch:<StyleTitle>{officeUserData.branchName}</StyleTitle>
                  </div>
               </Block>
            )}

            <div>{userRole}</div>
         </Container>

         <DropDownMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
         >
            <Divider />
            <StyledMenuItem onClick={handleOpenWindow}>
               <img src={Logout} alt="Logout" />
               Exit
            </StyledMenuItem>
         </DropDownMenu>

         <ConfirmModal
            open={openWindow}
            onClose={handleCloseWindow}
            onConfirm={confirmFunction}
            title="Are you sure you want to exit?"
            confirmButtonTitle="Exit"
         />

         {isMobileScreen && (
            <>
               <MobileDrawer
                  onOpen={() => setIsMobileDrawerOpened(true)}
                  role={role}
                  onClose={() => setIsMobileDrawerOpened(false)}
                  isOpen={isMobileDrawerOpened}
               />

               {role && role === ROLES.ROLE_MASTER && (
                  <MobileDrawerForCouries
                     onOpen={() => setIsCourierDrawerForModalIsOpened(true)}
                     onClose={() => setIsCourierDrawerForModalIsOpened(false)}
                     isOpen={isCourierDrawerForModalIsOpened}
                  />
               )}
            </>
         )}
      </MainContainer>
   )
}

Navigation.propTypes = {
   role: PropTypes.string,
   officeUserData: PropTypes.shape({
      address: PropTypes.string,
      branchName: PropTypes.string,
      companyName: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      image: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
   }),
}

Navigation.defaultProps = {
   role: PropTypes.string,
   officeUserData: {},
}

export default Navigation

const StyleTitle = styled.span`
   font-weight: 600;
   font-size: 16px;
   line-height: 22px;
   color: #000000;
   margin-left: 7px;
   white-space: nowrap;
`
const Block = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-around;
   width: 300px;
   gap: 10px;
   margin-left: -10px;
   color: #565656;
`
const MainContainer = styled.nav`
   width: 100%;
   height: 75px;
   border-bottom: 1.5px solid #ececec;
   display: flex;
   justify-content: flex-end;

   position: sticky;
   left: 0;
   top: 0;
   z-index: 100;
   background-color: white;

   @media (max-width: 500px) {
      justify-content: stretch;
   }
`
const StyledMenuItem = styled(MenuItem)`
   font-weight: 600;
   font-size: 16px;
   line-height: 25px;
   /* color: #000000; */
   width: 130px;
   transition: all 0.1s ease;
   background: #f5f6ff;
   box-shadow: none;
   &:hover {
      background-color: #f5f6ff;
   }
   img {
      width: 19px;
      height: 19px;
      margin-left: 8px;
      margin-right: 13px;
   }
`

const StyledTypography = styled(Typography)`
   font-weight: 600;
   font-size: 18px;
   line-height: 25px;
   color: #232323;
   margin-left: 10px;
   min-width: 100px;
   img {
      margin-left: 10px;
   }
`

const NavMenu = styled(Box)`
   border-radius: 50px;
`
const DropDownMenu = styled(Menu)`
   margin-top: 5px;
   .MuiList-root {
      background: #f5f6ff;
   }
   .MuiDivider-root {
      border-bottom-width: 0px;
   }
`
const Container = styled.div`
   display: flex;
   align-items: center;
   height: 75px;
   margin-bottom: 21px;
   margin-right: 20px;

   @media (max-width: 500px) {
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 14px 16px;
      align-items: center;
   }
`

const StyledBurgerIconContainer = styled.div`
   display: flex;
   align-items: center;
`

const StyledIConButton = styled(IconButton)`
   color: #fff;
   &:hover {
      background-color: transparent;
   }
`

const StyledAvatar = styled(Avatar)`
   width: 35px;
   height: 35px;
`
