import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

// import { ReactComponent as PhoneOrderCard } from '../../../assets/icons/phoneOrderCard.svg'
// import { ReactComponent as UserIcon } from '../../../assets/icons/UserOfficeUser.svg'
// import { ReactComponent as UserAddress } from '../../../assets/icons/addressOrderCard.svg'
import Button from '../../../../components/UI/Button'
import CustomBreadcrumbs from '../../../../components/UI/Breadcrumbs'
import useEnqueueSnackbar from '../../../../components/UI/Notification/useEnqueueSnackbar'
import { getOrderByIdRequest } from '../../../../api/orderService'
import { formatDate } from '../../../../utils/helpers/dateTimeHelpers'

// import DynamicPhoneInput from '../../../components/UI/DynamicPhoneInput'
import { deliverOrder, editOrder } from '../../../../store/order/orderActions'
import { getOnlineCouriers } from '../../../../store/courier/courierAction'
import { getMasterTariffs } from '../../../../store/tariff/tariffActions'
import { getRegions } from '../../../../store/region/regionActions'
import { DriverCard } from '../DriverCard'
import {
   convertDriverNumbers,
   DRIVER_HOLDER,
   getCouriers,
   getDriver,
   getDriverStatus,
   getRecipientsAddress,
   isOrderInRegion,
   ORDER_STATUSES,
} from '../../../../utils/constants/general'
import { REGION_EDITABLE_STATUSES } from '../../../../utils/constants/order-constants'
import { Actions, OrderNums } from '../../../../components/UI/OrderUI'
import StatusSelect from '../../../../components/UI/StatusSelect/StatusSelect'
// import { getMasterCities } from '../../../store/city/cityActions'
import EditWorkRegionForm from './EditWorkRegionForm'

export default function EditRegionMaster() {
   const [order, setOrder] = useState({
      commentDriver: '',
      recipientsNumbers: [],
      sendersNumbers: [],
      sendersName: '',
      recipientsAddress: '',
      additionalInformation: '',
      barcode: '',
      regionName: '',
      createDate: '',
      id: '',
      recipientsName: '',
      sendersAddress: '',
      city: '',
      amountOfRansom: null,
      status: '',
      tariff: { name: '', price: '' },
      orderStatus: [],
      canEdit: { data: false, courier: false },
      payer: null,
      secondDriver: DRIVER_HOLDER,
      regionId: null,
      cityId: null,
      currentStatus: '',
   })

   const couriers = useSelector(getCouriers)

   const { orderId } = useParams()
   const notify = useEnqueueSnackbar()
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const getOrderById = async () => {
      try {
         const { data } = await getOrderByIdRequest(orderId)
         const recipientsNumbers = convertDriverNumbers(data.recipientsNumbers)
         const sendersNumbers = convertDriverNumbers(data.sendersNumbers)

         dispatch(getOnlineCouriers(notify, orderId))

         const isMasterCanEdit = (order) => {
            if (order.masterIndex === 'FIRST') {
               if (order.status === 'IN_STOCK') return { data: true }
            }

            if (isOrderInRegion(order.status)) {
               if (REGION_EDITABLE_STATUSES.includes(order.status)) {
                  return { data: false }
               }
            }
            return { data: false }
         }
         const canEdit = isMasterCanEdit(data)
         if (canEdit.data) {
            dispatch(getRegions(notify))
            dispatch(getMasterTariffs(notify))
         }
         setOrder({
            ...data,
            hasDrivers: {
               secondDriver: Boolean(data.drivers.second),
            },
            check: {
               secondDriver: getDriver(data.drivers.second),
            },
            secondDriver: getDriver(data.drivers.second),
            recipientsAddress: data.recipientsAddress || '',
            tariffId: data.tariff.id,
            regionName: data.regionB.region,
            regionId: data.regionB.id,
            recipientsNumbers,
            sendersNumbers,
            createDate: formatDate(data.createDate),
            fullAddress: getRecipientsAddress(data),
            canEdit: isMasterCanEdit(data),
            cityId: data.city.id,
            currentStatus: data.status,
         })
      } catch (error) {
         notify('Failed to get order data', 'error')
      }
   }

   const handleStatusChange = ({ target: { name, value } }) => {
      setOrder({ ...order, [name]: value })
   }

   useEffect(() => getOrderById(), [])

   const handleCourierChange = ({ target: { name, value } }) => {
      const { car, ...other } = couriers.find(({ id }) => id === value)
      setOrder({
         ...order,
         [name]: {
            ...other,
            regionName: order.secondDriver.regionName,
            car: car ? 'Yes' : 'No',
            driverChangeStatus: 'CHANGED',
         },
      })
   }

   const navigateToOrder = () => navigate(-1)

   const formSubmitHandler = (e) => {
      e.preventDefault()
      const updated = {
         ...order,
         sendersNumbers: order.sendersNumbers.map(({ num }) => num),
         recipientsNumbers: order.recipientsNumbers.map(({ num }) => num),
         payer: order.payer === 'true',
         drivers: { second: getDriverStatus(order, 'secondDriver') },
      }
      if (order.currentStatus === 'DELIVERED') {
         return dispatch(
            deliverOrder(order.id, updated, navigateToOrder, notify)
         )
      }

      return dispatch(editOrder(orderId, updated, notify, navigateToOrder))
   }

   const isMasterCanEditRegionCourier = () => {
      if (order.masterIndex !== 'FIRST') {
         return isOrderInRegion(order.status)
      }
      return false
   }

   const paths = {
      works: 'Works',
      edit: 'Edit',
      [orderId]: `#${orderId}`,
   }

   return (
      <form onSubmit={formSubmitHandler}>
         <CustomBreadcrumbs pathTranslate={paths} />
         <Header>
            <div>
               <OrderNums>
                  Order Number: <span>#{order.id}</span>
               </OrderNums>
               <OrderNums>
                  Barcode number: <span>{order.barcode || '-------'}</span>
               </OrderNums>
            </div>
            <div>
               <StatusSelect
                  options={[
                     {
                        label: ORDER_STATUSES[order.status]?.text,
                        id: order.status,
                     },
                     { label: 'Delivered', id: 'DELIVERED' },
                  ]}
                  name="currentStatus"
                  onChange={handleStatusChange}
                  value={order.currentStatus}
               />
            </div>
         </Header>
         <EditWorkRegionForm
            order={order}
            onChange={setOrder}
            canEdit={order.canEdit}
         />
         <DriverCard
            region={order.secondDriver.regionName || ''}
            driver={order.secondDriver}
            onChange={setOrder}
            couriers={couriers}
            onCourierChange={handleCourierChange}
            editable={isMasterCanEditRegionCourier()}
            name="secondDriver"
            comment={order.commentDriver || ''}
         />
         <Actions>
            <Button variant="outlined" onClick={navigateToOrder}>
               Cancel
            </Button>
            <Button variant="contained" type="submit">
               Save
            </Button>
         </Actions>
      </form>
   )
}

const Header = styled('div')`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 60px;
`
