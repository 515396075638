import { createSlice } from '@reduxjs/toolkit'

const companyState = {
   errorMessage: null,
   isLoading: false,
   companies: [],
   company: {},
}

const companySlice = createSlice({
   name: 'company',
   initialState: companyState,
   reducers: {
      getCompanies(state, { payload }) {
         state.isLoading = false
         state.companies = payload.companies
      },
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      createCompany(state) {
         state.isLoading = false
      },
   },
})

export const companyActions = companySlice.actions
export default companySlice
