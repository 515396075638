import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   works: [],
   clients: [],
   isLoading: false,
   totalPage: 1,
   isEachClientLoading: false,
   totalCount: 0,
}

const workSlice = createSlice({
   name: 'orderSlice',
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      getWorks(state, { payload }) {
         state.isLoading = false
         state.works = payload.works
         state.totalPage = payload.totalPage
         state.totalCount = payload.totalCount
      },
      cancelOrder(state, { payload }) {
         state.isLoading = false
         state.works = state.works.filter((order) => order.id !== payload.id)
      },
   },
})

export const workActions = workSlice.actions

export default workSlice
