import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './Button'

const ButtonsContainer = styled.div`
   display: flex;
   justify-content: space-between;
   width: 160px;
   margin-left: 130px;
   padding-top: 10px;
   & Button {
      width: 72px;
      height: 38px;
      font-size: 14px;
      line-height: 19px;
      cursor: pointer;
   }
`
const StyledBox = styled(Box)`
   background: #f5f6ff;
   border-radius: 10px;
   border-color: #f5f6ff;
   position: absolute;
   top: 50%;
   left: 50%;
   max-height: 100%;
   max-width: 100%;
   min-width: 320px;
   transform: translate(-50%, -50%);
   padding: 23px 23px 18px 23px;
`
const Title = styled(Typography)`
   font-weight: 400;
   font-size: 18px;
   line-height: 25px;
   color: #232323;
`
const BoxContainer = styled.div`
   padding-top: 20px;
`

const ConfirmModal = ({
   open,
   onClose,
   title,
   onConfirm,
   confirmButtonTitle,
}) => {
   return (
      <Modal
         open={open}
         onClose={onClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         <StyledBox>
            <Title>{title}</Title>
            <BoxContainer>
               <ButtonsContainer>
                  <Button
                     size="medium"
                     variant="outlined"
                     color="primary"
                     onClick={onClose}
                  >
                     Cancer
                  </Button>
                  <Button
                     size="medium"
                     variant="contained"
                     color="error"
                     onClick={onConfirm}
                  >
                     {confirmButtonTitle}
                  </Button>
               </ButtonsContainer>
            </BoxContainer>
         </StyledBox>
      </Modal>
   )
}

ConfirmModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   onConfirm: PropTypes.func.isRequired,
   title: PropTypes.string.isRequired,
   confirmButtonTitle: PropTypes.string.isRequired,
}

export default ConfirmModal
