import {
   createCourierRequest,
   getCouriersRequest,
   editCourierRequest,
   deleteCourierRequest,
   getOnlineCouriersRequest,
   getOrdersHistoryRequest,
   getCourierActiveWorksRequest,
} from '../../api/courierService'
import { uploadImageRequest } from '../../api/imageService'
import { courierActions } from './courierSlice'
import { COURIER_ONLINE_STATUSES } from '../../utils/constants/general'
import { getErrorMessage } from '../../utils/helpers/general'

export const createCourier = (
   courierData,
   formDataFile,
   notify,
   closeModalAfterRequest,
   page
) => {
   return async (dispatch) => {
      try {
         dispatch(courierActions.setCreateLoading({ isLoading: true }))
         const courierDataWithLogo = { ...courierData, image: '' }
         if (courierData) {
            const { data: fileName } = await uploadImageRequest(formDataFile)
            courierDataWithLogo.image = fileName
         }
         await createCourierRequest(courierDataWithLogo)
         notify('Courier added successfully', 'success')
         closeModalAfterRequest()
         dispatch(getCouriers(notify, '', page))

         dispatch(courierActions.setCreateLoading({ isLoading: false }))
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(courierActions.setCreateLoading({ isLoading: false }))
      }
   }
}

export const getCouriers = (notify, name, page) => {
   return async (dispatch) => {
      try {
         dispatch(courierActions.setLoading({ isLoading: true }))
         const response = await getCouriersRequest(name, page)
         const { content: couriers, totalPage, totalCount } = response.data
         dispatch(
            courierActions.getCouriers({ couriers, totalPage, totalCount })
         )
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')

         dispatch(courierActions.setLoading({ isLoading: false }))
      }
   }
}

export const getSideDrawerCouriers = (notify) => {
   return async (dispatch) => {
      try {
         dispatch(courierActions.setLoading({ isLoading: true }))
         const { data } = await getCouriersRequest('', 1, 100)
         const onlineNotAssignedCouriers = data.content.filter(
            (c) => c.driverStatus === COURIER_ONLINE_STATUSES.NOT_ASSIGNED
         )
         const onlineAssignedCouriers = data.content
            .filter((c) => c.driverStatus === COURIER_ONLINE_STATUSES.ASSIGNED)
            .sort((a, b) => a.orderCount - b.orderCount)

         const offlineCouriers = data.content.filter(
            (c) => c.driverStatus === COURIER_ONLINE_STATUSES.OFFLINE
         )

         const sortedCouriers = [
            ...onlineNotAssignedCouriers,
            ...onlineAssignedCouriers,
            ...offlineCouriers,
         ]

         dispatch(courierActions.getSideDrawerCouriers(sortedCouriers))
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(courierActions.setLoading({ isLoading: false }))
      }
   }
}

export const editCourier = (
   notify,
   courierId,
   courierData,
   formDataFile,
   closeModalAfterRequest,
   page,
   getData
) => {
   return async (dispatch) => {
      try {
         dispatch(courierActions.setLoading({ isLoading: true }))
         const courierDataWithAvatar = {
            ...courierData,
         }
         if (formDataFile) {
            const { data: fileName } = await uploadImageRequest(formDataFile)
            courierDataWithAvatar.image = fileName
         }
         const { data } = await editCourierRequest(
            courierId,
            courierDataWithAvatar
         )
         notify('Courier successfully changed', 'success')
         closeModalAfterRequest()
         if (page) {
            return dispatch(getCouriers(notify, '', page))
         }
         dispatch(courierActions.setLoading({ isLoading: false }))
         return getData(data)
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         return dispatch(courierActions.setLoading({ isLoading: false }))
      }
   }
}

export const deleteCourier = (
   notify,
   courierId,
   closeDeleteModal,
   page,
   onNavigate = () => {}
) => {
   return async (dispatch, getState) => {
      try {
         dispatch(courierActions.setLoading({ isLoading: true }))
         await deleteCourierRequest(courierId)
         dispatch(courierActions.deleteCourier({ courierId }))
         notify('Courier deleted successfully', 'success')
         closeDeleteModal()
         const { courier } = getState()
         if (courier.couriers.length === 1) {
            if (courier.page !== 1 && courier.page - 1 >= 1) {
               dispatch(courierActions.setPage(courier.page - 1))
            }
         }
         if (page) dispatch(getCouriers(notify, '', page))
         onNavigate()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(courierActions.setLoading({ isLoading: false }))
         closeDeleteModal()
      }
   }
}

export const getOnlineCouriers = (notify, orderId) => {
   return async (dispatch) => {
      try {
         dispatch(courierActions.setLoading({ isLoading: true }))
         const response = await getOnlineCouriersRequest(orderId)
         const couriers = response.data
         dispatch(
            courierActions.getOnlineCouriers({
               couriers,
            })
         )
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')

         dispatch(
            courierActions.setLoading({
               isLoading: false,
            })
         )
      }
   }
}

export const getOrderHistory = (
   notify,
   courierId,
   startDate,
   endDate,
   page
) => {
   return async (dispatch) => {
      try {
         dispatch(courierActions.setLoading({ isLoading: true }))
         const { data: orderData } = await getOrdersHistoryRequest(
            courierId,
            startDate,
            endDate,
            page
         )
         dispatch(courierActions.getOrderHistory({ orderData }))
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')

         dispatch(courierActions.setLoading({ isLoading: false }))
      }
   }
}

export const getCourierActiveWorks = (notify, courierId) => {
   return async (dispatch) => {
      try {
         dispatch(courierActions.setLoading({ isLoading: true }))
         const response = await getCourierActiveWorksRequest(courierId)

         dispatch(courierActions.setActiveWorks(response.data.content))
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')

         dispatch(courierActions.setLoading({ isLoading: false }))
      }
   }
}
