import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   cities: [],
   isLoading: false,
}

const citySlice = createSlice({
   name: 'citySlice',
   initialState,
   reducers: {
      setCities(state, { payload }) {
         state.cities = payload.cities
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const cityActions = citySlice.actions

export default citySlice
