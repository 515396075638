import React from 'react'
import { SwipeableDrawer } from '@material-ui/core'
import List from '@mui/material/List'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MenuItem from './MenuItems'
import { MENU_ITEMS, ROLES } from '../../utils/constants/general'
import { ReactComponent as LogoIcon } from '../../assets/icons/Supplysync.svg'
import { ReactComponent as CloseDrawerIcon } from '../../assets/icons/CLoseDrawerIcon.svg'

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
   & .MuiDrawer-paper {
      width: 80%;
      background-color: #f5f6ff;
   }
`

const StyledList = styled(List)`
   padding: 0 20px 15px 16px;
   &:first-child {
      padding-top: 67px;
   }
`

const LogoContainer = styled.div`
   display: flex;
   width: 100%;
   justify-content: center;
   margin-top: 8px;
   margin-bottom: 30px;
`

const CloseDrawerIconContainer = styled.div`
   margin-top: 56px;
   margin-left: 16px;
`

const MobileDrawerMenu = ({ isOpen, onClose, onOpen, role }) => {
   const navigate = useNavigate()

   const toggleDrawer = (open) => (event) => {
      if (
         event &&
         event.type === 'keydown' &&
         (event.key === 'Tab' || event.key === 'Shift')
      ) {
         return
      }

      if (open) {
         onOpen()
      }

      onClose()
   }

   return (
      <StyledSwipeableDrawer
         anchor="left"
         open={isOpen}
         onClose={toggleDrawer(false)}
         onOpen={toggleDrawer(true)}
      >
         <CloseDrawerIconContainer>
            <IconButton onClick={toggleDrawer(false)}>
               <CloseDrawerIcon />
            </IconButton>
         </CloseDrawerIconContainer>

         <LogoContainer>
            <LogoIcon />
         </LogoContainer>

         <StyledList>
            {MENU_ITEMS[role] &&
               MENU_ITEMS[role].length > 0 &&
               MENU_ITEMS[role].map((el) => (
                  <MenuItem
                     el={el}
                     key={el.path}
                     onClick={() => {
                        onClose()
                        navigate(el.path)
                     }}
                  />
               ))}
         </StyledList>
      </StyledSwipeableDrawer>
   )
}

MobileDrawerMenu.propTypes = {
   isOpen: PropTypes.bool.isRequired,
   onOpen: PropTypes.func.isRequired,
   onClose: PropTypes.func.isRequired,
   role: PropTypes.oneOf([ROLES.ROLE_HOST, ROLES.ROLE_MASTER]).isRequired,
}

export default MobileDrawerMenu
