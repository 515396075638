import React, { useEffect, useRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal as MuiModal } from '@mui/material'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const CONTAINER_PADDINGS = {
   OUTER_RIGHT: {
      THINNER: 18,
      WIDER: 30,
   },
   INNER_RIGHT: {
      THINNER: 0,
      WIDER: 18,
   },
}

const Modal = (props) => {
   const { open, children, ...otherProps } = props

   return (
      <MuiModal
         open={open}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         {...otherProps}
      >
         <StyledBox>{children}</StyledBox>
      </MuiModal>
   )
}
export default Modal

Modal.propTypes = {
   open: PropTypes.bool.isRequired,
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
   ]).isRequired,
}

export const ModalTitle = styled(Typography)`
   &.MuiTypography-root {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #232323;
      border-bottom: 1px solid #d4d4d4;
      padding: 20px 0px;
   }
`

export const ModalFooter = styled.div`
   width: 100%;
   padding: 0 30px 30px 30px;
`

export const ModalBody = ({ children }) => {
   const [refChanged, setRefChanged] = useState(false)
   const [outerContainerRightPadding, setOuterContainerRightPadding] = useState(
      CONTAINER_PADDINGS.OUTER_RIGHT.WIDER
   )
   const [innerContainerRightPadding, setInnerContainerRightPadding] = useState(
      CONTAINER_PADDINGS.INNER_RIGHT.THINNER
   )

   const innerRef = useRef()

   const registerInnerRef = useCallback((node) => {
      if (node !== null) {
         innerRef.current = node
         setRefChanged(true)
      }
   }, [])

   const isScrollExist = (scrollHeight, clientHeight) => {
      return scrollHeight > clientHeight
   }

   useEffect(() => {
      if (innerRef.current) {
         if (
            isScrollExist(
               innerRef.current.scrollHeight,
               innerRef.current.clientHeight
            )
         ) {
            setOuterContainerRightPadding(
               CONTAINER_PADDINGS.OUTER_RIGHT.THINNER
            )
            setInnerContainerRightPadding(CONTAINER_PADDINGS.INNER_RIGHT.WIDER)
         } else {
            setOuterContainerRightPadding(CONTAINER_PADDINGS.OUTER_RIGHT.WIDER)
            setInnerContainerRightPadding(
               CONTAINER_PADDINGS.INNER_RIGHT.THINNER
            )
         }
      }
   }, [refChanged])

   return (
      <ContainerBox rightPadding={outerContainerRightPadding}>
         <Container
            ref={registerInnerRef}
            rightPadding={innerContainerRightPadding}
         >
            {children}
         </Container>
      </ContainerBox>
   )
}

ModalBody.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
   ]).isRequired,
}

const StyledBox = styled(Box)`
   background: #f5f6ff;
   border-radius: 10px;
   border-color: #f5f6ff;
   position: absolute;
   top: 50%;
   left: 50%;
   max-width: 100%;
   width: 551px;
   max-height: 622px;
   transform: translate(-50%, -50%);
   outline: none;
`

const Container = styled.div`
   box-sizing: border-box;
   padding-top: 10px;
   overflow-x: hidden;
   overflow-y: auto;
   max-height: 440px;
   min-height: 60px;
   ::-webkit-scrollbar {
      width: 9px;
      background-color: #f9f9fd;
      margin-left: 15px;
   }
   ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      width: 90px;
      max-height: 275px;
      background: #ececec;
   }
   ::-webkit-scrollbar-track {
      width: 9px;
      background: #ffffff;
      border-radius: 10px;
   }

   padding-right: ${(props) => props.rightPadding}px;
`
const ContainerBox = styled.div`
   padding: 20px ${(props) => props.rightPadding}px 20px 30px;
`

export const ModalButtonsContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   & Button {
      height: 39px;
      font-size: 14px;
      line-height: 19px;
      margin-left: 18px;
      cursor: pointer;
   }
`
