import {
   createMasterRequest,
   deleteMasterRequest,
   editMasterRequest,
   getMastersRequest,
   getOfficeUserProfileRequest,
} from '../../api/masterService'
import { masterActions } from './masterSlice'
import { uploadImageRequest } from '../../api/imageService'
import { getErrorMessage } from '../../utils/helpers/general'

export const createMaster = (
   masterData,
   formDataFile,
   notify,
   closeModalAfterRequest
) => {
   return async (dispatch) => {
      try {
         dispatch(
            masterActions.setLoading({
               isLoading: true,
            })
         )
         const masterDataWithLogo = {
            ...masterData,
            logo: '',
         }
         if (formDataFile) {
            const { data: fileName } = await uploadImageRequest(formDataFile)
            masterDataWithLogo.logo = fileName
         }
         await createMasterRequest(masterDataWithLogo)
         dispatch(getMasters(notify))
         notify('Master added successfully', 'success')
         closeModalAfterRequest()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(
            masterActions.setLoading({
               isLoading: false,
            })
         )
      }
   }
}

export const editMaster = (
   id,
   masterData,
   formDataFile,
   notify,
   closeModalAfterRequest
) => {
   return async (dispatch) => {
      try {
         dispatch(
            masterActions.setLoading({
               isLoading: true,
            })
         )
         const masterDataWithLogo = {
            ...masterData,
            logo: '',
         }
         if (formDataFile) {
            const { data: fileName } = await uploadImageRequest(formDataFile)
            masterDataWithLogo.logo = fileName
         }
         await editMasterRequest(id, masterDataWithLogo)
         dispatch(getMasters(notify))
         notify('Master changed successfully', 'success')
         closeModalAfterRequest()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(
            masterActions.setLoading({
               isLoading: false,
            })
         )
      }
   }
}

export const getMasters = (notify, companyId) => {
   return async (dispatch) => {
      try {
         dispatch(masterActions.setLoading({ isLoading: true }))
         const response = await getMastersRequest(companyId)
         const masters = response.data
         dispatch(
            masterActions.getMasters({
               masters,
            })
         )
      } catch (error) {
         dispatch(
            masterActions.setLoading({
               isLoading: false,
            })
         )
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const deleteMaster = (id, notify, closeDeleteModal) => {
   return async (dispatch) => {
      try {
         dispatch(masterActions.setLoading({ isLoading: true }))
         await deleteMasterRequest(id)
         dispatch(masterActions.deleteMaster({ id }))
         notify('Master removed successfully', 'success')
         closeDeleteModal()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(
            masterActions.setLoading({
               isLoading: false,
            })
         )
         closeDeleteModal()
      }
   }
}

export const getOfficeUser = (notify) => {
   return async (dispatch) => {
      try {
         const { data: officeUserData } = await getOfficeUserProfileRequest()
         localStorage.setItem('master', JSON.stringify(officeUserData))
         dispatch(masterActions.getOfficeUserData({ officeUserData }))
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}
