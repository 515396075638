import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import CouiersList from './CouiersList'
import { ReactComponent as CheckIcon } from '../../assets/icons/checkIcon.svg'
import { ReactComponent as CouriersIcon } from '../../assets/icons/CouriersIcon.svg'
import { ReactComponent as OfficeUserIcon } from '../../assets/icons/MasterIcon.svg'
import Logout from '../../assets/icons/Logout.svg'
import ConfirmModal from '../../components/UI/ConfirmModal'
import ProfilIcon from '../../assets/icons/ProfilMaster.svg'
import { logOut } from '../../store/user/userActions'
import { getSideDrawerCouriers } from '../../store/courier/courierAction'
import useEnqueueSnackbar from '../../components/UI/Notification/useEnqueueSnackbar'

const SideDrawerForCouriers = () => {
   const notify = useEnqueueSnackbar()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { sideDrawerCouriers } = useSelector((state) => state.courier)

   useEffect(() => {
      const timer = setInterval(() => {
         dispatch(getSideDrawerCouriers(notify))
      }, 5000)
      return () => clearInterval(timer)
   }, [])

   useEffect(() => {
      dispatch(getSideDrawerCouriers(notify))
   }, [])

   const [anchorEl, setAnchorEl] = useState(null)
   const open = Boolean(anchorEl)

   const [openWindow, setOpenWindow] = useState(false)
   const handleOpenWindow = () => setOpenWindow(true)
   const handleCloseWindow = () => setOpenWindow(false)

   const handleClick = (event) => setAnchorEl(event.currentTarget)

   const handleClose = () => setAnchorEl(null)

   const confirmFunction = () => {
      dispatch(logOut())
      handleCloseWindow()
   }
   const navigateToOfficeUserProfile = () => navigate(`/dashboard/profile`)

   return (
      <Container>
         <HeaderAvatar>
            <NavMenu>
               <Tooltip title="Account settings">
                  <StyledIConButton onClick={handleClick}>
                     <Avatar>
                        <OfficeUserIcon />
                     </Avatar>
                     <HeaderTitle>Master</HeaderTitle>
                     <CheckIcon />
                  </StyledIConButton>
               </Tooltip>
            </NavMenu>
         </HeaderAvatar>
         <ContentAvatar>
            <Avatar>
               <CouriersIcon />
            </Avatar>
            <HeaderTitle>Courier list</HeaderTitle>
         </ContentAvatar>
         <DropDownMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
         >
            <Divider />
            <StyledMenuItem onClick={navigateToOfficeUserProfile}>
               <img src={ProfilIcon} alt="Logout" />
               Profile
            </StyledMenuItem>
            <StyledBorderBottom />
            <StyledMenuItem onClick={handleOpenWindow}>
               <img src={Logout} alt="Logout" />
               Exit
            </StyledMenuItem>
         </DropDownMenu>
         <ConfirmModal
            open={openWindow}
            onClose={handleCloseWindow}
            onConfirm={confirmFunction}
            title="Are you sure you want to exit?"
            confirmButtonTitle="Exit"
         />
         <CourierListContainer>
            <CouiersList couriers={sideDrawerCouriers} />
         </CourierListContainer>
      </Container>
   )
}

export default SideDrawerForCouriers

const Container = styled.div`
   background: #f5f6ff;
   width: 20%;
   //position: fixed;
   right: 0;
   height: 100%;
`

const HeaderAvatar = styled.div`
   border-bottom: 1px solid #d4d4d4;
   margin: 0px 19px;
   padding: 17px 0px;
`
const Avatar = styled.div`
   background: #f5f6ff;
   width: 40px;
   height: 40px;
   border-radius: 50%;
   margin-right: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid #c4c4c4;
`
const HeaderTitle = styled.div`
   font-weight: 400;
   font-size: 18px;
   line-height: 25px;
   color: #232323;
   margin-right: 10px;
`
const ContentAvatar = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   padding-bottom: 25px;
   padding-top: 27px;
`

const NavMenu = styled(Box)`
   border-radius: 50px;
`
const StyledIConButton = styled('div')`
   display: flex;
   align-items: center;
   justify-content: center;
   color: #fff;
   &:hover {
      background-color: transparent;
   }
`

const DropDownMenu = styled(Menu)`
   margin-top: 5px;
   .MuiList-root {
      background: #f5f6ff;
   }
   .MuiDivider-root {
      border-bottom-width: 0px;
   }
`
const StyledBorderBottom = styled.div`
   border-bottom: 1px solid #ececec;
   width: 119px;
   margin-left: 20px;
`
const StyledMenuItem = styled(MenuItem)`
   font-weight: 600;
   font-size: 16px;
   line-height: 25px;
   color: #000000;
   width: 160px;
   transition: all 0.1s ease;
   background: #f5f6ff;
   &:hover {
      background-color: #f5f6ff;
   }
   img {
      width: 19px;
      height: 19px;
      margin-left: 8px;
      margin-right: 13px;
   }
`

const CourierListContainer = styled.div`
   position: relative;
   overflow-y: scroll;
   height: 100vh;
`
