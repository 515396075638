import React from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

const Input = React.forwardRef((props, ref) => {
   const { shrink } = props

   const labelProps = { required: false }

   if (shrink) {
      labelProps.shrink = true
   }

   return (
      <StyledInput
         {...props}
         inputRef={ref}
         autoComplete="off"
         InputLabelProps={labelProps}
      />
   )
})

export default Input

const StyledInput = styled(TextField)`
   &.MuiFormControl-root {
      width: 100%;
   }
   .MuiInputBase-formControl {
      background: ${({ error }) => (error ? 'rgb(250,231,231)' : '#ffffff')};
      min-width: 100px;
      height: 42px;
      border-radius: 10px;
      padding: 10px 8px;
      color: #9c9b9b;
   }
   .Mui-focused {
      color: #232323;
   }

   .MuiInputBase-input {
      color: #232323;
   }

   .MuiInputLabel-root {
      transform: translate(20px, 10px);
   }
   .MuiInputLabel-shrink {
      transform: translate(14px, -10px);
      font-size: 13px;
      line-height: 19px;
   }
   & .MuiOutlinedInput-root {
      &.Mui-focused fieldset,
      :hover fieldset {
         border-color: #d4d4d4;
         color: #9c9b9b;
      }
   }
`
Input.propTypes = {
   shrink: PropTypes.bool,
}

Input.defaultProps = {
   shrink: null,
}
