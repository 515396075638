import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as WithCar } from '../../assets/icons/CarOfCouriers.svg'
import { ReactComponent as WithoutCar } from '../../assets/icons/ManCouriers.svg'
import LoadCompanyIconName from '../../components/UI/LoadCompanyIconName'

const ICONS = {
   true: WithCar,
   false: WithoutCar,
}

const colorType = {
   NOT_ASSIGNED: '#36AC0C',
   ASSIGNED: '#C91E1E',
   OFFLINE: '#868686',
}
const onlineOrOfflineColor = {
   NOT_ASSIGNED: '#36AC0C',
   ASSIGNED: '#36AC0C',
   OFFLINE: '#868686',
}

const TranslateStatuses = {
   OFFLINE: 'Оффлайн',
   ASSIGNED: 'Назначен',
   NOT_ASSIGNED: 'Свободен',
}

const CourierItem = ({ name, status, hasCar, image, orders, onClick }) => {
   return (
      <Container onClick={onClick}>
         <ContainerIcon status={status}>
            <LoadCompanyIconName companyIcon={image} companyName="" />
         </ContainerIcon>

         <div>
            <Title>{name}</Title>
            <Block status={status}>
               <StyledIcon status={status} as={ICONS[hasCar]} />
               <Span>{TranslateStatuses[status]}</Span>
               <Span>{status === 'ASSIGNED' && orders}</Span>
            </Block>
         </div>
      </Container>
   )
}

CourierItem.propTypes = {
   name: PropTypes.string.isRequired,
   status: PropTypes.string.isRequired,
   image: PropTypes.string,
   hasCar: PropTypes.bool.isRequired,
   orders: PropTypes.number.isRequired,
   onClick: PropTypes.func.isRequired,
}

CourierItem.defaultProps = {
   image: PropTypes.string,
}
export default CourierItem

const StyledIcon = styled.svg`
   & path {
      fill: ${(props) => (props.status ? colorType[props.status] : '#000000')};
   }
`

const Span = styled.div`
   margin-left: 5px;
`

const Container = styled.div`
   display: flex;
   border-bottom: 1px solid #d4d4d4;
   padding-bottom: 10px;
   padding-top: 15px;
   margin: 0px 18px;
   cursor: pointer;
`
const Title = styled.div`
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 22px;
   color: #000000;
   width: 150px;
   padding-left: 20px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
`
const Block = styled.div`
   display: inline-flex;
   justify-content: space-between;
   color: ${(props) => (props.status ? colorType[props.status] : '#000000')};
   margin-left: 25px;
   padding-top: 12px;
   font-size: 14px;
   line-height: 19px;

   svg {
      width: 24px;
      height: 24px;
   }
`

const ContainerIcon = styled.div`
   border-radius: 50%;
   &::before {
      content: '';
      border-radius: 50%;
      height: 11px;
      width: 11px;
      position: absolute;
      left: 60px;
      border: 2px solid #fff;
      background: ${(props) =>
         props.status ? onlineOrOfflineColor[props.status] : '#000000'};
   }
`
