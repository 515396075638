import PropTypes from 'prop-types'
import styled from 'styled-components'
import imageNotFound from '../../assets/images/imageNotFound.jpg'
import { loadImageFile } from '../../utils/helpers/imageHelpers'
import { useImageLoader } from '../../hooks/useImageLoader'

const ImageAndTitle = styled.div`
   //width: 100%;
   //max-width: 90%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   font-weight: 600;
   line-height: 19px;
   //white-space: nowrap;
   word-break: break-word;
`

const ImageContainer = styled.div`
   width: 50px;
   height: 50px;
   margin-right: 10px;
   display: flex;
   flex-shrink: 0;
`

const Image = styled('img')({
   width: '100%',
   height: '100%',
   objectFit: 'cover',
   borderRadius: '50%',
})

const LoadCompanyIconName = ({ companyIcon, companyName, ...props }) => {
   const { loadingStyles, isImageValid, setImageLoaded } =
      useImageLoader(companyIcon)

   return (
      <ImageAndTitle {...props}>
         <ImageContainer style={loadingStyles}>
            {isImageValid ? (
               <Image
                  onLoad={() => setImageLoaded(true)}
                  src={loadImageFile(companyIcon)}
                  alt="IconCompany"
               />
            ) : (
               <Image src={imageNotFound} alt="NotFound" />
            )}
         </ImageContainer>
         {companyName}
      </ImageAndTitle>
   )
}

LoadCompanyIconName.propTypes = {
   companyIcon: PropTypes.node.isRequired,
   companyName: PropTypes.string.isRequired,
}

export default LoadCompanyIconName
