import { createSlice } from '@reduxjs/toolkit'

const branchState = {
   isLoading: false,
   branches: [],
   companies: [],
}

const branchSlice = createSlice({
   name: 'branch',
   initialState: branchState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
         state.errorMessage = null
      },
      createBranch(state) {
         state.isLoading = false
      },
      setBranches(state, { payload }) {
         if (Array.isArray(payload.branches)) {
            state.branches = payload.branches
         }
      },
      getCompanies(state, { payload }) {
         state.companies = payload.companies
      },
      // deleteBranch(state, { payload }) {
      //    // state.isLoading = false
      //    return {
      //       ...state,
      //       isLoading: false,
      //       branches: state.branches.filter(
      //          (branch) => branch.id !== payload.id
      //       ),
      //    }
      // },
   },
})
export const branchActions = branchSlice.actions
export default branchSlice
