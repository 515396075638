import { createGlobalStyle } from 'styled-components'
import { createTheme } from '@mui/material/styles'

const GlobalStyle = createGlobalStyle`
* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   font-style: normal;
   font-weight: normal;
   font-family: 'Nunito'!important;
    line-height: normal;
  
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-webkit-autofill:active {
   color: #1F6ED4 !important;
   background-color: transparent !important;
  background: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

iframe {
    display: none;
}

`
export const styledTheme = {
   error: '#C91E1E',
   success: '#36AC0C',
   info: '#F5F6FF',
   primary: '#1F6ED4',
   secondary: '#EAEBF5',
   black: '#232323',
   warning: '#C91E1E',
}

export const theme = createTheme({
   palette: {
      primary: {
         main: '#1F6ED4',
      },
      secondary: {
         main: '#EAEBF5',
      },
      success: {
         main: '#36AC0C',
      },
      error: {
         main: '#C91E1E',
      },
      info: {
         main: '#D1E5FE',
      },
      warning: {
         main: '#C91E1E',
      },
   },
})

export default GlobalStyle
