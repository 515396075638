import { ReactComponent as AppointeddIcon } from '../../assets/icons/appointed.svg'

import { ReactComponent as Courier } from '../../assets/icons/courier.svg'
import { ReactComponent as Processing } from '../../assets/icons/processing.svg'
import { ReactComponent as InStock } from '../../assets/icons/sklad.svg'
import { ReactComponent as CourierOnTheWay } from '../../assets/icons/courierOnTheWay.svg'
import { ReactComponent as OnTheWay } from '../../assets/icons/onTheWay.svg'
import { ReactComponent as Delivered } from '../../assets/icons/delivered.svg'
import { ReactComponent as Canceled } from '../../assets/icons/cancel.svg'
import { ReactComponent as NotAssigned } from '../../assets/icons/Not_assigned.svg'
import { ReactComponent as CompanyIcon } from '../../assets/icons/CompanyIcon.svg'
import { ReactComponent as BranchIcon } from '../../assets/icons/BranchesIcon.svg'
import { ReactComponent as MasterIcon } from '../../assets/icons/MastersIcon.svg'
import { ReactComponent as TariffIcon } from '../../assets/icons/TariffsIcon.svg'
import { ReactComponent as OrderIconOfficeUser } from '../../assets/icons/OfficeUserIcon.svg'
import { ReactComponent as CourierIconOfficeUser } from '../../assets/icons/FullColored.svg'
import { ReactComponent as WorkIconOfficeUser } from '../../assets/icons/OfficeUserIconWork.svg'
import { ReactComponent as RegionOfficeUserIcon } from '../../assets/icons/region.svg'
import { ReactComponent as WorkHistoryIcon } from '../../assets/icons/workHistory.svg'
// import { ReactComponent as JuridicEntityIcon } from '../../assets/icons/JuridicEntityIcon.svg'

export const baseURL =
   process.env.REACT_APP_SERVER_URL ||
   'https://development.supplysync.us/api/v1'
// 'http://localhost:8080/api/v1'
// 'http://taigan-production-backend.eu-central-1.elasticbeanstalk.com/api/v1'

export const ROLES = {
   ROLE_HOST: 'ROLE_HOST',
   ROLE_MASTER: 'ROLE_MASTER',
}

export const ROUTES = {
   BRANCHES: 'branches',
   JURIDICAL_ENTITIES: 'juridicalEntities',
   JURIDICAL_ENTITIES_REPORT: 'juridicalEntitiesReport',
   JURIDICAL_ENTITIES_REPORT_DETAILS: 'juridicalEntitiesReport/:id',
   REGIONS: 'regions',
   MASTERS: 'masters',
   ORDERS: 'orders',
   COURIERS: 'couriers',
   WORKS: 'works',
   WORKS_HISTORY: 'workHistory',
   WORKINNER: 'works/:workId',
   WORKEDIT: 'works/:orderId/edit',
   COMPANY: 'companies',
   LOGIN: '/login',
   DASHBOARD: '/dashboard',
   TARIFFS: 'tariffs',
   ORDEREDIT: 'orders/:orderId/edit',
   COURIERPROFILE: 'couriers/:courierId/*',
   ORDERINNER: 'orders/:orderId',
   PROFILE: '/',
   COURIER_HISTORY: 'history',
   ACTIVE_WORKS: 'activeWorks',
   FEEDBACK: 'feedback',
   HOSTORDERS: 'hostOrders',
   OFFICEUSER_PROFILE: 'profile',
   PUBLICPAGE: '/track/:workId',
   COMMENT: 'comment/:workId',
   DETAILSORDER: 'couriers/:courierId/history/:orderId',
}

export const JWT_TOKEN_KEY = 'TAIGAN_JWT_TOKEN_KEY'
export const ROLE = 'TAIGAN_ROLE'

export const REGIONS = [
   {
      label: 'Chuy',
      value: '1',
   },
   {
      label: 'Issyk-Kul',
      value: '2',
   },
   {
      label: 'Naryn',
      value: '3',
   },
   {
      label: 'Talas',
      value: '4',
   },
   {
      label: 'Jalal-Abad',
      value: '5',
   },
   {
      label: 'Bishkek',
      value: '6',
   },
   {
      label: 'Batken',
      value: '7',
   },
   {
      label: 'Osh',
      value: '8',
   },
]

export function isThereDataInArray(array) {
   return array?.length
}

export const TARIFF_TYPES = {
   CITY: 'CITY',
   CITY_EXPRESS: 'CITY_EXPRESS',
   OUT_OF_CITY: 'OUT_OF_CITY',
   REGION: 'REGION',
   getAll: 'getAll',
}

export const HUMAN_READABLE_TARIFF_TYPES = {
   CITY: 'Around the city',
   CITY_EXPRESS: 'City Express',
   OUT_OF_CITY: 'Out of city',
   REGION: 'Region',
}
export const ORDER_STATUSES = {
   NOT_ASSIGNED: {
      text: 'Not assigned ',
      color: '#C91E1E',
      icon: <NotAssigned />,
   },
   IN_PROCESSING: {
      text: 'In processing ',
      color: '#FF9F0A',
      icon: <Processing />,
   },
   ASSIGNED: {
      text: 'Appointed ',
      color: '#36AC0C',
      icon: <AppointeddIcon />,
   },
   ASSIGNED_REGION: {
      text: 'Appointed ',
      color: '#36AC0C',
      icon: <AppointeddIcon />,
   },
   PICK_UP: {
      text: 'Pick up courier',
      color: '#36AC0C',
      icon: <Courier />,
   },
   IN_STOCK: {
      text: 'In stock',
      color: '#36AC0C',
      icon: <InStock />,
   },
   DRIVER_ON_THE_WAY: {
      text: 'Courier on the way',
      color: '#36AC0C',
      icon: <CourierOnTheWay />,
   },
   DELIVERED: {
      text: 'Delivered',
      color: '#36AC0C',
      icon: <Delivered />,
   },
   CANCELED: {
      text: 'Cancelled',
      color: '#C91E1E',
      icon: <Canceled />,
   },
   ON_THE_WAY: {
      text: 'On the road',
      color: '#36AC0C',
      icon: <OnTheWay />,
   },
   IN_STOCK_REGION: {
      text: 'At the regional warehouse',
      color: '#36AC0C',
      icon: <InStock />,
   },
   PICK_UP_REGION: {
      text: 'With the courier (Region)',
      color: '#36AC0C',
      icon: <Courier />,
   },
}

export const WORK_CARD_COLORS = {
   DEFAULT: {
      background: '#F8F8FF',
      border: '1px solid #D4D4D4',
   },
   HANGING: {
      background: '#FFF0F0',
      border: '1px solid #d03838',
   },
   DELIVERY_IS_LATE: {
      background: 'rgba(248, 197, 55, 0.8)',
      border: '1px solid #FF9F0A',
   },
}

export const STATUS_TYPES = [
   { label: 'Assigned', id: 'ASSIGNED' },
   { label: 'Courier on the way', id: 'DRIVER_ON_THE_WAY' },
   { label: 'With the courier', id: 'PICK_UP' },
   { label: 'In stock', id: 'IN_STOCK' },
   { label: 'On the road.', id: 'ON_THE_WAY' },
   { label: 'In stock (region)', id: 'IN_STOCK_REGION' },
   { label: 'Assigned (region)', id: 'ASSIGNED_REGION' },
   { label: 'Picked up (region)', id: 'PICK_UP_REGION' },
   { label: 'Delivered', id: 'DELIVERED' },
   { label: 'Canceled', id: 'CANCELED' },
   { label: 'All', id: 'ALL_ORDERS' },
]

export const ACTIVE_WORK_STATUS_LABELS = [
   { label: 'Assigned', id: 'ASSIGNED' },
   { label: 'Courier on the way', id: 'DRIVER_ON_THE_WAY' },
   { label: 'Picked up', id: 'PICK_UP' },
   { label: 'In stock', id: 'IN_STOCK' },
   { label: 'On the way', id: 'ON_THE_WAY' },
   { label: 'In stock (region)', id: 'IN_STOCK_REGION' },
   { label: 'Assigned (region)', id: 'ASSIGNED_REGION' },
   { label: 'Picked up (region)', id: 'PICK_UP_REGION' },
   { label: 'All', id: 'ALL_ORDERS' },
]

export const WORK_HISTORY_STATUS_LABELS = [
   { label: 'Delivered', id: 'DELIVERED' },
   { label: 'Canceled', id: 'CANCELED' },
   { label: 'All', id: 'ALL_ORDERS' },
]

export const STATUSES = {
   IN_PROCESSING: 'IN_PROCESSING',
   ASSIGNED: 'ASSIGNED',
   DRIVER_ON_THE_WAY: 'DRIVER_ON_THE_WAY',
   PICK_UP: 'PICK_UP',
   IN_STOCK: 'IN_STOCK',
   ON_THE_WAY: 'ON_THE_WAY',
   IN_STOCK_REGION: 'IN_STOCK_REGION',
   ASSIGNED_REGION: 'ASSIGNED_REGION',
   PICK_UP_REGION: 'PICK_UP_REGION',
   DELIVERED: 'DELIVERED',
   CANCELED: 'CANCELED',
}

export const getStatusWithRegion = (status, A, B, type) => {
   if (type !== 'REGION') return ORDER_STATUSES[status].text
   const statuses = {
      IN_STOCK: `In stock ${A.name && `(${A.name})`}`,
      ASSIGNED: `Assigned ${A.name && `(${A.name})`}`,
      PICK_UP: `Picked up (${A.name})`,
      DRIVER_ON_THE_WAY: `Courier on the way (${A.name})`,
      IN_STOCK_REGION: `In stock (${B.name})`,
      ASSIGNED_REGION: `Assigned (${B.name})`,
      PICK_UP_REGION: `Picked up (${B.name})`,
      ON_THE_WAY: 'On the way',
      DELIVERED: 'Delivered',
      CANCELED: 'Canceled',
      IN_PROCESSING: 'In processing',
   }
   return statuses[status]
}

export const ORDER_STATUS_LABELS = {
   IN_PROCESSING: 'In processing',
   ASSIGNED: 'Assigned',
   IN_STOCK: 'In stock',
   ON_THE_WAY: 'On the way',
   IN_STOCK_REGION: 'In stock (region)',
   ASSIGNED_REGION: 'Assigned (region)',
   PICK_UP_REGION: 'Picked up (region)',
   DELIVERED: 'Delivered',
   CANCELED: 'Canceled',
   DRIVER_ON_THE_WAY: 'Courier on the way',
   PICK_UP: 'Picked up',
}

export const REGION_STATUSES = [
   'IN_STOCK_REGION',
   'ASSIGNED_REGION',
   'PICK_UP_REGION',
]

export const IS_MASTER_CAN_EDIT_ORDER = [
   ...REGION_STATUSES,
   'ON_THE_WAY',
   'DELIVERED',
]

export const isOrderInRegion = (status) => REGION_STATUSES.includes(status)

export const isOrderFromClient = (statuses) => statuses.length > 9

export const DRIVER_HOLDER = {
   name: '',
   car: 'Car existence',
   phone: '996 --- -- -- --',
   image: '',
   regionName: '',
   id: '',
}

export const isOrderTypeRegion = (type) => type === 'REGION'

export const getRecipientsAddress = ({
   type,
   regionB,
   city,
   recipientsAddress,
}) => {
   if (isOrderTypeRegion(type)) {
      return `${regionB?.name} ${city?.name} ${recipientsAddress}`
   }
   return recipientsAddress
}

export const convertDriverNumbers = (arrayOfNumbers) => {
   return arrayOfNumbers.map((num) => ({
      num,
      id: Math.random().toString(),
   }))
}

export const getDriver = (driver) => {
   if (driver)
      return {
         ...driver,
         car: driver.car ? 'Yes' : 'No',
         driverChangeStatus: 'NOT_CHANGED',
      }
   return { ...DRIVER_HOLDER }
}

export const getTariffsFromStore = ({ tariff: { tariffs } }, type) => {
   if (isOrderTypeRegion(type)) {
      return tariffs.filter(({ type }) => isOrderTypeRegion(type))
   }
   return tariffs.filter(({ type }) => !isOrderTypeRegion(type))
}

export const ORDER_PLACEHOLDER = {
   id: '',
   barcode: '',
   recipientsAddress: '',
   recipientsNumbers: [],
   recipientsName: '',
   additionalInformation: '',
   sendersAddress: '',
   sendersName: '',
   sendersNumbers: [],
   driver: { id: '' },
   driverId: [],
   createDate: '',
   tariffId: '',
   cityId: null,
   regionId: null,
   amountOfRansom: null,
   payer: null,
   paymentAmount: '',
   tariff: { name: '', price: 0 },
   commentDriver: '',
   firstDriver: { ...DRIVER_HOLDER },
   secondDriver: { ...DRIVER_HOLDER },
   orderStatus: [],
   prices: [],
   price: '',
   regionName: '',
   status: '',
   canEdit: { data: false },
   currentStatus: '',
}

export const isNumberValid = (num) => num.replace(/[^\d]/g, '').length === 11

export function isPhoneNumberValid(value) {
   const phoneRegex = /^\d{11}$/
   return phoneRegex.test(value)
}
export const isNumbersValid = (nums) => {
   return nums.some(({ num }) => num.replace(/[^\d]/g, '').length === 11)
}

export const getCouriers = ({ courier: { onlineCouriers } }) => {
   return onlineCouriers.filter(({ orderCount }) => orderCount < 4)
}

export const getDriverStatus = (order, type) => {
   if (!order.hasDrivers[type] && order[type].id !== '') {
      return { id: order[type].id, driverChangeStatus: 'ASSIGNED' }
   }
   if (order.check[type].phone === order[type].phone) {
      return { driverChangeStatus: 'NOT_CHANGED' }
   }
   return {
      id: order[type].id,
      driverChangeStatus: order[type].driverChangeStatus,
   }
}

export const WEIGHT_LABELS = {
   UP_TO_THREE: 'Up to 3 kg',
   UP_TO_FIVE: 'Up to 5 kg',
   UP_TO_TEN: 'Up to 10 kg',
   UP_TO_FIFTEEN: 'Up to 15 kg',
   MORE_THAN_FIFTEEN: '15+ kg',
}

export const getRegion = (order) => {
   if (isOrderTypeRegion(order.type)) {
      if (isOrderInRegion(order.status)) return order.regionB.name
   }
   return order.regionA.name
}

export const SECOND_MASTER_EDITABLE_STATUSES = [
   'IN_STOCK_REGION',
   'ASSIGNED_REGION',
   'PICK_UP_REGION',
   'ON_THE_WAY',
   'DELIVERED',
]

export const canSecondMasterEdit = (order) => {
   return (
      order.masterIndex !== 'FIRST' &&
      IS_MASTER_CAN_EDIT_ORDER.includes(order.status)
   )
}

export const isOrderTypeRegionAndFromClient = (order) => {
   return isOrderTypeRegion(order.type) && isOrderFromClient(order.orderStatus)
}

export const canAssignCourier = (element) => {
   if (['ON_THE_WAY', 'DELIVERED', 'CANCELED'].includes(element.status)) {
      return false
   }

   if (isOrderTypeRegion(element.type)) {
      if (isOrderFromClient(element.orderStatus)) {
         if (element.masterIndex === 'FIRST') {
            return !IS_MASTER_CAN_EDIT_ORDER.includes(element.status)
         }
         return IS_MASTER_CAN_EDIT_ORDER.includes(element.status)
      }
      if (element.masterIndex !== 'FIRST') {
         return isOrderInRegion(element.status)
      }
      return false
   }

   return true
}

export const MOBILE_APP_LINKS = {
   play_market:
      'https://play.google.com/store/apps/details?id=com.peaksoft.taigan.plus',
   app_store: 'https://apps.apple.com/ru/app/taigan-delivery/id1631790990',
}

export const MENU_ITEMS = {
   [ROLES.ROLE_HOST]: [
      {
         text: 'Companies',
         icon: CompanyIcon,
         path: ROUTES.COMPANY,
      },
      {
         text: 'Branches',
         icon: BranchIcon,
         path: ROUTES.BRANCHES,
      },
      {
         text: 'Masters',
         icon: MasterIcon,
         path: ROUTES.MASTERS,
      },
      {
         text: 'Tariffs',
         icon: TariffIcon,
         path: ROUTES.TARIFFS,
      },
      {
         text: 'Orders',
         icon: OrderIconOfficeUser,
         path: ROUTES.HOSTORDERS,
      },
      {
         text: 'Regions',
         icon: RegionOfficeUserIcon,
         path: ROUTES.REGIONS,
      },
      // {
      //    text: 'Juridic Entity',
      //    icon: JuridicEntityIcon,
      //    path: ROUTES.JURIDICAL_ENTITIES,
      // },
   ],
   [ROLES.ROLE_MASTER]: [
      {
         text: 'Courier',
         icon: CourierIconOfficeUser,
         path: ROUTES.COURIERS,
      },
      {
         text: 'Orders',
         icon: OrderIconOfficeUser,
         path: ROUTES.ORDERS,
      },
      {
         text: 'Works',
         icon: WorkIconOfficeUser,
         path: ROUTES.WORKS,
      },
      {
         text: 'History',
         icon: WorkHistoryIcon,
         path: ROUTES.WORKS_HISTORY,
      },
      // {
      //    text: 'Juridic Entity',
      //    icon: JuridicEntityIcon,
      //    path: ROUTES.JURIDICAL_ENTITIES_REPORT,
      // },
   ],
}

export const COURIER_ONLINE_STATUSES = {
   NOT_ASSIGNED: 'NOT_ASSIGNED',
   OFFLINE: 'OFFLINE',
   ASSIGNED: 'ASSIGNED',
}

export const DELIVERY_IS_LATE_DURATION_IN_MINUTES = {
   CITY: {
      ACTIVE: 105,
      FINISHED: 120,
   },
   EXPRESS: {
      ACTIVE: 55,
      FINISHED: 60,
   },
}

export const HUMAN_READABLE_TARIFF_LABELS = [
   { label: 'All', id: 'getAll' },
   { label: HUMAN_READABLE_TARIFF_TYPES.CITY, id: TARIFF_TYPES.CITY },
   {
      label: HUMAN_READABLE_TARIFF_TYPES.CITY_EXPRESS,
      id: TARIFF_TYPES.CITY_EXPRESS,
   },
   {
      label: HUMAN_READABLE_TARIFF_TYPES.OUT_OF_CITY,
      id: TARIFF_TYPES.OUT_OF_CITY,
   },
   { label: HUMAN_READABLE_TARIFF_TYPES.REGION, id: TARIFF_TYPES.REGION },
]
