import { baseURL } from '../constants/general'
import { deleteImageRequest } from '../../api/imageService'

export const loadImageFile = (fileName) => {
   if (fileName) {
      return `${baseURL}/files/download/${fileName}`
   }
   return null
}

const fileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']
export const validateFile = (file) => {
   let errorMessage = null
   let isValid = true
   const { size, type } = file

   if (!file) {
      return false
   }
   if (!fileTypes.includes(type)) {
      errorMessage = 'The file must be in jpeg or png format'
      isValid = false
   }
   if (size / 1024 / 1014 > 5) {
      errorMessage = 'Your file must be no larger than 5MB'
      isValid = false
   }

   return {
      errorMessage,
      isValid,
   }
}

export const deleteImageFromServer = async (imageNameFromServer, notify) => {
   try {
      await deleteImageRequest(imageNameFromServer)
   } catch (error) {
      notify(`Failed to delete photo ${error.message}`, 'error')
   }
}

export function validateImage(url) {
   const fullUrl = loadImageFile(url)
   if (!fullUrl) {
      return false
   }

   if (!/(http|https):\/\//i.test(fullUrl)) {
      return false
   }

   const imageExtensions = ['.jpg', '.jpeg', '.gif', '.png', '.webp']
   if (!imageExtensions.find((ext) => fullUrl.includes(ext))) {
      return false
   }

   return true
}
