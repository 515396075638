import React from 'react'
import { Link as MuiLink, styled } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

export default function CustomBreadcrumbs({ pathTranslate, exception }) {
   const { pathname } = useLocation()

   const [mainPath, ...paths] = pathname.split('/').filter((x) => x)
   const pathnames = paths
      .map((path) => ({ path, name: pathTranslate[path] || path }))
      .filter(exception)

   const renderLinkItem = (i) => ({
      to: `/${mainPath}/${paths.slice(0, i + 1).join('/')}`,
      color: i === pathnames.length - 1 ? '#000000' : '#D4D4D4',
   })
   return (
      <StyledBreadCrumbs>
         {pathnames.map(({ path, name }, i) => (
            <MuiLink {...renderLinkItem(i)} component={Link} key={path}>
               {name}
            </MuiLink>
         ))}
      </StyledBreadCrumbs>
   )
}
const StyledBreadCrumbs = styled(Breadcrumbs)(() => ({
   '&': {
      marginTop: '36px',
      marginBottom: '38px',
   },
   '& a': {
      textDecoration: 'none',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '130%',
      letterSpacing: '0.03em',
   },
}))

CustomBreadcrumbs.propTypes = {
   pathTranslate: PropTypes.shape({}).isRequired,
   exception: PropTypes.func,
}

CustomBreadcrumbs.defaultProps = {
   exception: (arr) => arr,
}
