import formDataInstance from '../config/formDataInstance'

export const uploadImageRequest = (logoFile) => {
   return formDataInstance.post('/files/upload', logoFile)
}

export const getImageRequest = (fileName) => {
   return formDataInstance.get(`/files/download/${fileName}`)
}

export const deleteImageRequest = (fileName) => {
   return formDataInstance.delete(`/files/delete/${fileName}`)
}
