import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'
import { Select as MuiSelect } from '@mui/material'
import styled from 'styled-components'
import InputLabel from '@mui/material/InputLabel'
import { Controller } from 'react-hook-form'
import { ReactComponent as DropDownIcon } from '../../assets/icons/Arrows.svg'

const Select = (props) => {
   const {
      label,
      options,
      value,
      onChange,
      getOptionLabel,
      getOptionValue,
      getOptionId,
      allCompanies,
      onClick,
      inputLabelProps,
      control,
      name,
      error,
      helperText,
      ...otherProps
   } = props
   console.log(value)

   const select = (onChange, value) => (
      <StyledFormControl error={error}>
         <StyledInputLabel sx={inputLabelProps} autoComplete="off">
            {label}
         </StyledInputLabel>
         <StyledSelect
            MenuProps={{
               PaperProps: {
                  sx: { width: 511 },
               },
            }}
            label={label}
            name={name}
            labelId="demo-simple-select-autowidth-label"
            onChange={onChange}
            IconComponent={DropDownIcon}
            value={value}
            {...otherProps}
         >
            {options?.map((option) => (
               <StyledMenuItem
                  key={getOptionId ? getOptionId(option) : option.id}
                  value={getOptionValue ? getOptionValue(option) : option.id}
               >
                  {getOptionLabel ? getOptionLabel(option) : option.label}
               </StyledMenuItem>
            ))}
         </StyledSelect>
         {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
      </StyledFormControl>
   )

   return control ? (
      <Controller
         control={control}
         name={name}
         defaultValue=""
         render={({ field: { onChange, value } }) => {
            return select(onChange, value)
         }}
      />
   ) : (
      select(onChange, value)
   )
}
export default Select

Select.propTypes = {
   name: PropTypes.string,
   control: PropTypes.shape({}),
   label: PropTypes.string,
   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   onChange: PropTypes.func,
   options: PropTypes.arrayOf(
      PropTypes.shape({
         label: PropTypes.string,
         value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
   ).isRequired,
   getOptionLabel: PropTypes.func,
   getOptionValue: PropTypes.func,
   getOptionId: PropTypes.func,
   onClick: PropTypes.func,
   allCompanies: PropTypes.string,
   helperText: PropTypes.string,
   inputLabelProps: PropTypes.shape({}),
   error: PropTypes.shape({}),
}

Select.defaultProps = {
   label: null,
   inputLabelProps: null,
   getOptionId: null,
   getOptionLabel: null,
   getOptionValue: null,
   onClick: null,
   value: '',
   allCompanies: null,
   control: null,
   name: '',
   helperText: null,
   error: null,
   onChange: () => '',
}

const StyledFormControl = styled(FormControl)`
   width: 100%;
`

export const StyledInputLabel = styled(InputLabel)`
   &.MuiInputLabel-formControl {
      transform: translate(24px, 10px);
      color: #9c9b9b;
      &.MuiInputLabel-shrink {
         &.Mui-focused {
            color: #232323 !important;
         }
         transform: translate(15px, -10px);
         font-size: 13px;
         color: rgba(0, 0, 0, 0.6) !important;
         line-height: 19px;
      }
   }
`
const StyledMenuItem = styled(MenuItem)`
   &.MuiMenuItem-root {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      font-weight: 600;
      background: white;
      //width: 100%;
      height: 42px;
      font-size: 16px;
      line-height: 22px;
      padding-left: 31px;
      color: '#0000000';
      :focus,
      :hover {
         color: #1f6ed4;
         background: #eaebf5;
         line-height: 22px;
      }
   }
   .MuiTouchRipple-root {
      line-height: 22px;
   }
   width: 100%;
`
const StyledSelect = styled(MuiSelect)`
   width: 100%;
   height: 42px;
   border-bottom: none;
   color: #232323;
   .MuiSelect-outlined {
      padding-left: 6px !important;
   }

   MuiInputBase-input .MuiPaper-root {
      .MuiList-root {
         padding-bottom: 0;
      }
   }

   &.Mui-error {
      background-color: rgb(250, 231, 231) !important;
   }

   .MuiSelect-icon {
      position: absolute;
      right: 22px;
      top: 13px;
      bottom: 13px;
   }
   &.MuiOutlinedInput-root {
      &.Mui-focused fieldset,
      :hover fieldset {
         border-color: #d4d4d4;
         border-width: 1px;
         color: #9c9b9b;
      }
   }
   & legend > span {
      font-size: 13px;
      padding-left: 8px;
      padding-right: 8px;
   }
   .MuiOutlinedInput-input {
      padding: 0;
   }
   &.MuiInputBase-root {
      border-radius: 10px;
      background: #ffffff;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 0px;
   }
`

const StyledHelperText = styled('p')`
   color: #c91e1e;
   font-weight: 400;
   font-size: 0.75rem;
   line-height: 1.66;
   letter-spacing: 0.03333em;
   text-align: left;
   margin: 3px 14px 0;
`
