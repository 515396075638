import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as CarOrderIcon } from '../../../assets/icons/CarOrderCard.svg'
import { ReactComponent as PhoneOrderCard } from '../../../assets/icons/phoneOrderCard.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/UserOfficeUser.svg'

import Input from '../../../components/UI/Input'
import Select from '../../../components/UI/Select'

export const DriverCard = ({
   driver,
   onChange,
   onCourierChange,
   couriers,
   editable,
   name,
   region,
   comment,
}) => {
   const handleChange = ({ target: { name, value } }) => {
      onChange((prev) => ({ ...prev, [name]: value }))
   }
   if (!editable && (!driver || !driver.name)) return null
   return (
      <Card>
         <ContentBlock>
            <ContentItem>
               <CardTitle>Courier({region})</CardTitle>
               <CardInfo>
                  <span>
                     <UserIcon />
                  </span>
                  {editable ? (
                     <StyledSelect
                        name={name}
                        label="Choose a courier"
                        options={couriers}
                        value={couriers.length ? driver?.id : ''}
                        onChange={onCourierChange}
                        getOptionLabel={(option) => option.firstName}
                        getOptionValue={(option) => option.id}
                     />
                  ) : (
                     driver?.name
                  )}
               </CardInfo>
               <CardInfo>
                  <span>
                     <PhoneOrderCard />
                  </span>
                  {driver?.phone}
               </CardInfo>
               <CardInfo>
                  <span>
                     <CarOrderIcon />
                  </span>
                  {driver?.car}
               </CardInfo>
            </ContentItem>
            <RightSide>
               {name === 'secondDriver' || (
                  <div>
                     <CardTitle>Буду в (В пути):</CardTitle>
                     {editable ? (
                        <StyledInput
                           name="commentDriver"
                           value={comment}
                           onChange={handleChange}
                           multiline
                           rows={6}
                        />
                     ) : (
                        comment
                     )}
                  </div>
               )}
            </RightSide>
         </ContentBlock>
      </Card>
   )
}

DriverCard.propTypes = {
   couriers: PropTypes.arrayOf(PropTypes.shape({})),
   driver: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      phone: PropTypes.string,
      car: PropTypes.string,
      status: PropTypes.string,
   }),
   comment: PropTypes.string,
   onChange: PropTypes.func,
   editable: PropTypes.bool,
   name: PropTypes.string,
   onCourierChange: PropTypes.func,
   region: PropTypes.string.isRequired,
}

DriverCard.defaultProps = {
   editable: false,
   name: '',
   onCourierChange: () => {},
   onChange: () => {},
   couriers: [],
   driver: null,
   comment: '',
}

const RightSide = styled.div`
   margin-left: auto;
   width: 350px;
`

const Card = styled.div`
   margin-bottom: 12px;
   min-height: 270px;
   background: rgb(255, 255, 255);
   border: 1px solid #d4d4d4;
   box-sizing: border-box;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
   border-radius: 6px;
   padding: 40px 60px 40px 60px;
`

const ContentBlock = styled.div`
   display: flex;
   justify-content: space-between;
`
const ContentItem = styled.div`
   width: 300px;
   margin-right: 25px;
   white-space: nowrap;
`

const CardTitle = styled.div`
   font-size: 18px;
   line-height: 25px;
   color: #a2a2a2;
   margin-bottom: 20px;
`

const CardInfo = styled.div`
   font-size: 18px;
   line-height: 25px;
   display: flex;
   align-items: center;
   .container {
      margin: 0px;
      padding: 5px 0 5px 0;
      legend {
         span {
            margin: 0px;
         }
      }
   }
   & span {
      margin: 15px 10px 15px 5px;
   }
`

const StyledInput = styled(Input)`
   .MuiInputBase-formControl {
      margin-top: 5px;
      height: auto;
   }
`

const StyledSelect = styled(({ className, ...props }) => (
   <StyledSelectRoot {...props} MenuProps={{ paper: className }} />
))``

const StyledSelectRoot = styled(Select)`
   padding: 0px !important;
`
