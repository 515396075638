import styled from '@emotion/styled'
import { InputAdornment } from '@mui/material'
import PropTypes from 'prop-types'
import { ORDER_STATUSES } from '../../../utils/constants/general'
import Select from '../Select'

const StatusSelect = ({ options, onChange, value, name }) => {
   return (
      <StyledSelect
         startAdornment={
            <InputAdornment position="start">
               {ORDER_STATUSES[value]?.icon}
            </InputAdornment>
         }
         options={options}
         onChange={onChange}
         value={value}
         name={name}
      />
   )
}

StatusSelect.propTypes = {
   options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
   onChange: PropTypes.func.isRequired,
   value: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
}

const StyledSelect = styled(Select)`
   .MuiSelect-outlined {
      padding: 0 !important;
      padding-right: 32px !important;
   }
   .MuiOutlinedInput-notchedOutline {
      span {
         display: none;
      }
   }
`

export default StatusSelect
