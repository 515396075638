import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const StyledHelperText = styled('p')`
   color: #c91e1e;
   font-weight: 400;
   font-size: 0.75rem;
   line-height: 1.66;
   letter-spacing: 0.03333em;
   text-align: left;
   margin: 3px 14px 0;
`

const StyledMaskInput = styled(InputMask)`
   border-radius: 10px;
   font-size: 16px;
   line-height: 22px;
   width: 100%;
   padding: 10px 23px;
   color: #232323;
   border: 1px solid #d4d4d4;

   ::before,
   ::after {
      border-bottom: 0px;
      content: none;
   }

   :focus {
      color: #232323;
      outline: none;
      border: 2px solid #d4d4d4;
   }

   ::placeholder {
      color: #9c9b9b;
   }

   :-webkit-autofill,
   :-webkit-autofill:hover,
   :-webkit-autofill:focus,
   :-webkit-autofill:active {
      box-shadow: 0 0 0 30px
         ${({ error }) => (+error ? 'rgb(250,231,231)' : '#ffffff')} inset !important;
   }

   background-color: ${({ error }) =>
      +error ? 'rgb(250,231,231)' : '#ffffff'};
`

const PhoneInput = React.forwardRef((props, ref) => {
   const { helperText, name, control, error, onChange, value } = props
   const registerRef = (inputRef) => {
      if (ref) {
         ref(inputRef ? inputRef.inputElement : null)
      }
   }

   const input = (onChange, value) => (
      <StyledMaskInput
         {...props}
         error={error}
         ref={registerRef}
         onChange={onChange}
         value={value}
         // mask={value ? '+\\1 999 999 9999' : null}
         mask={'+\\1 999 999 9999'}
         placeholder="+1 ___ ___ ____ *"
      />
   )

   return control ? (
      <>
         <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({ field: { onChange, value } }) => {
               return input(onChange, value)
            }}
         />
         {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
      </>
   ) : (
      input(onChange, value)
   )
})

PhoneInput.propTypes = {
   value: PropTypes.string,
   helperText: PropTypes.string,
   onChange: PropTypes.func,
   name: PropTypes.string.isRequired,
   control: PropTypes.shape({}),
   error: PropTypes.bool.isRequired,
}

PhoneInput.defaultProps = {
   value: '',
   helperText: '',
   control: null,
   onChange: null,
}

export default PhoneInput
