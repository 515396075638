import { tariffActions } from './tariffSlice'
import {
   createTariffRequest,
   deleteTariffRequest,
   editTariffRequest,
   getMasterTariffsRequest,
   getTariffsRequest,
} from '../../api/tariffService'
import { getErrorMessage } from '../../utils/helpers/general'

export const createTariff = (tariffData, notify, closeModalAfterRequest) => {
   return async (dispatch) => {
      try {
         dispatch(
            tariffActions.setLoading({
               isLoading: true,
            })
         )
         await createTariffRequest(tariffData)
         dispatch(getTariffs(notify))
         notify('Tariff created successfully', 'success')
         closeModalAfterRequest()
      } catch (error) {
         dispatch(
            tariffActions.setLoading({
               isLoading: false,
            })
         )
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const editTariff = (id, tariffData, notify, closeModalAfterRequest) => {
   return async (dispatch) => {
      try {
         dispatch(
            tariffActions.setLoading({
               isLoading: true,
            })
         )
         await editTariffRequest(id, tariffData)
         dispatch(getTariffs(notify))
         notify('Tariff successfully changed', 'success')
         closeModalAfterRequest()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(
            tariffActions.setLoading({
               isLoading: false,
            })
         )
      }
   }
}

export const deleteTariff = (id, notify, closeDeleteModal) => {
   return async (dispatch) => {
      try {
         dispatch(tariffActions.setLoading({ isLoading: true }))
         await deleteTariffRequest(id)
         await dispatch(getTariffs(notify))
         notify('Tariff removed successfully', 'success')
         closeDeleteModal()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(
            tariffActions.setLoading({
               isLoading: false,
            })
         )
         closeDeleteModal()
      }
   }
}

export const getTariffs = (notify) => {
   return async (dispatch) => {
      try {
         dispatch(tariffActions.setLoading({ isLoading: true }))
         const { data } = await getTariffsRequest()
         const tariffsByRegionName = {}
         let counter = 1
         data.forEach((item) => {
            const regionName = item.region.region
            if (tariffsByRegionName[regionName]) {
               tariffsByRegionName[regionName].tariffs.push(item)
            } else {
               tariffsByRegionName[regionName] = {
                  // eslint-disable-next-line no-plusplus
                  counter: counter++,
                  id: item.region.id,
                  region: item.region,
                  tariffs: [item],
               }
            }
         })

         const sortTariffs = (tariffs) => {
            return [...tariffs].sort((a, b) => {
               if (b.type === 'CITY') return 1
               if (a.type === 'CITY_EXPRESS') return -1
               if (a.type === 'OUT_OF_CITY') return -1
               if (a.type === 'REGION') return -1
               return 0
            })
         }

         const parsedTariffs = Object.values(tariffsByRegionName)
         const sortedTariffs = parsedTariffs.map((region) => {
            return { ...region, tariffs: sortTariffs(region.tariffs) }
         })
         dispatch(tariffActions.getTariffs({ tariffs: sortedTariffs }))
      } catch (error) {
         dispatch(tariffActions.setLoading({ isLoading: false }))
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const getMasterTariffs = (notify) => {
   return async (dispatch) => {
      try {
         dispatch(tariffActions.setLoading({ isLoading: true }))
         const { data: tariffs } = await getMasterTariffsRequest()

         dispatch(tariffActions.getTariffs({ tariffs }))
      } catch (error) {
         dispatch(tariffActions.setLoading({ isLoading: false }))
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}
