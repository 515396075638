import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
   Card,
   CardInfoEdit,
   CardTitle,
   ContentBlock,
   ContentInner,
   DriverCommentInput,
   Flexer,
   PayerBlock,
   StyledComment,
   StyledSelect,
} from '../../../../components/UI/OrderUI'

import { ReactComponent as PhoneOrderCard } from '../../../../assets/icons/phoneOrderCard.svg'
import { ReactComponent as UserIcon } from '../../../../assets/icons/UserOfficeUser.svg'
import { ReactComponent as UserAddress } from '../../../../assets/icons/addressOrderCard.svg'
import { ReactComponent as Price } from '../../../../assets/icons/priceOfficeUser.svg'
import { ReactComponent as CarOrderIcon } from '../../../../assets/icons/CarOrderCard.svg'
import { ReactComponent as TimeOrderCard } from '../../../../assets/icons/TimeOrderCard.svg'
import { ReactComponent as ThemisIcon } from '../../../../assets/icons/themis.svg'
import { ReactComponent as MoneyIcon } from '../../../../assets/icons/moneyIcon.svg'

import DynamicPhoneInput from '../../../../components/UI/DynamicPhoneInput'
import Input from '../../../../components/UI/Input'
import { getMasterCities } from '../../../../store/city/cityActions'
import useEnqueueSnackbar from '../../../../components/UI/Notification/useEnqueueSnackbar'
import { WEIGHT_LABELS } from '../../../../utils/constants/general'
import WeightSelect from '../../../../components/UI/WeightSelect/WeightSelect'

const EditWorkRegionForm = ({ canEdit, order, onChange }) => {
   const Component = useMemo(() => {
      return canEdit.data ? (
         <EditableForm order={order} onChange={onChange} />
      ) : (
         <ReadonlyForm order={order} />
      )
   }, [canEdit.data, order])
   return Component
}

export default EditWorkRegionForm

EditWorkRegionForm.propTypes = {
   canEdit: PropTypes.shape({
      data: PropTypes.bool,
   }).isRequired,
   order: PropTypes.shape({}).isRequired,
   onChange: PropTypes.func.isRequired,
}

const Container = styled('div')``

const EditableForm = ({ order, onChange }) => {
   const dispatch = useDispatch()
   const notify = useEnqueueSnackbar()

   const { regions } = useSelector((state) => state.region)
   const { cities } = useSelector((state) => state.city)

   const [weightOptions, setWeightOptions] = useState([])

   const handleInputChange = ({ target: { name, value } }) => {
      onChange({ ...order, [name]: value })
   }

   const handleWeightChange = ({ target: { name, value } }) => {
      const { price, kg } = weightOptions.find((item) => item.kg === value)
      onChange({ ...order, [name]: kg, paymentAmount: price })
   }

   const handleChangeNumbers = ({ target: { name, value } }, index) => {
      const updatedValues = { ...order }
      updatedValues[name][index].num = value
      onChange(updatedValues)
   }

   const addSecondNum = (name) => {
      return () => {
         if (order[name].length > 1) return
         const updatedValues = { ...order }
         updatedValues[name].push({ num: '', id: Math.random().toString() })
         onChange(updatedValues)
      }
   }

   const removeSecondNum = (name, id) => {
      return () => {
         onChange({
            ...order,
            [name]: order[name].filter((num) => num.id !== id),
         })
      }
   }

   const isMounted = useRef(false)

   useEffect(() => {
      if (order.regionId) {
         dispatch(getMasterCities(order.regionId, notify))
         if (isMounted.current) {
            onChange({ ...order, cityId: '' })
         }
         isMounted.current = true
      }
   }, [order.regionId])

   useEffect(() => {
      if (order.cityId) {
         const weights = cities.find((item) => item.id === order.cityId)
         setWeightOptions(weights?.prices || weightOptions)
      }
   }, [order.cityId])

   useEffect(() => {
      setWeightOptions(order.city?.prices)
   }, [])
   return (
      <Container>
         <Card>
            <ContentBlock>
               <ContentInner>
                  <CardTitle>Sender:</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <UserIcon />
                     </span>
                     <Input
                        required
                        label="Senders name"
                        name="sendersName"
                        onChange={handleInputChange}
                        value={order.sendersName}
                     />
                  </CardInfoEdit>
                  {order.sendersNumbers.map((number, index) => (
                     <CardInfoEdit key={number.id}>
                        <span>
                           <PhoneOrderCard />
                        </span>
                        <DynamicPhoneInput
                           error={false}
                           key={number.id}
                           data={number}
                           order={index}
                           name="sendersNumbers"
                           onChange={handleChangeNumbers}
                           addSecondNum={addSecondNum}
                           removeSecondNum={removeSecondNum}
                        />
                     </CardInfoEdit>
                  ))}
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <Input
                        required
                        label="Senders address"
                        name="sendersAddress"
                        onChange={handleInputChange}
                        value={order.sendersAddress}
                     />
                  </CardInfoEdit>
               </ContentInner>
               <ContentInner>
                  <CardTitle>Recipient:</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <UserIcon />
                     </span>
                     <Input
                        required
                        label="Recipients name"
                        name="recipientsName"
                        onChange={handleInputChange}
                        value={order.recipientsName}
                     />
                  </CardInfoEdit>
                  {order.recipientsNumbers.map((number, index) => (
                     <CardInfoEdit key={number.id}>
                        <span>
                           <PhoneOrderCard />
                        </span>
                        <DynamicPhoneInput
                           error={false}
                           key={number.id}
                           data={number}
                           order={index}
                           name="recipientsNumbers"
                           onChange={handleChangeNumbers}
                           removeSecondNum={removeSecondNum}
                           addSecondNum={addSecondNum}
                        />
                     </CardInfoEdit>
                  ))}
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <StyledSelect
                        required
                        name="regionId"
                        label="Recipient's address (Region)"
                        options={regions}
                        value={regions.length ? order.regionId : ''}
                        onChange={handleInputChange}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                     />
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <StyledSelect
                        required
                        options={cities}
                        label="Recipient's address (City)"
                        name="cityId"
                        onChange={handleInputChange}
                        value={cities.length ? order.cityId : ''}
                        getOptionLabel={(option) => option.name}
                     />
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <Input
                        required
                        label="Recipient's address"
                        name="recipientsAddress"
                        onChange={handleInputChange}
                        value={order.recipientsAddress}
                     />
                  </CardInfoEdit>
               </ContentInner>
            </ContentBlock>
         </Card>
         <Card>
            <ContentBlock>
               <ContentInner>
                  <CardTitle>Delivery details</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <CarOrderIcon />
                     </span>
                     {order.tariff.name}
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <Price />
                     </span>
                     {order.amountOfRansom}
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <Flexer>
                        <span>
                           <ThemisIcon />
                        </span>
                        <WeightSelect
                           options={weightOptions}
                           label="Weight"
                           name="weight"
                           onChange={handleWeightChange}
                           value={weightOptions.length ? order.weight : ''}
                           getOptionLabel={(option) => WEIGHT_LABELS[option.kg]}
                           getOptionId={(option) => option.kg}
                           getOptionValue={(option) => option.kg}
                        />
                        <span>
                           <MoneyIcon />
                        </span>
                        <Input
                           required
                           name="paymentAmount"
                           label="Cost"
                           value={order.paymentAmount}
                           onChange={handleInputChange}
                        />
                     </Flexer>
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <TimeOrderCard />
                     </span>
                     {order.createDate}
                  </CardInfoEdit>
               </ContentInner>
               <ContentInner>
                  <CardTitle>Order comment:</CardTitle>
                  <DriverCommentInput
                     multiline
                     rows={6}
                     name="additionalInformation"
                     onChange={handleInputChange}
                     value={order.additionalInformation}
                  />
               </ContentInner>
            </ContentBlock>
            <PayerBlock>
               <CardTitle>The payment for the service is made by:</CardTitle>
               <CardInfoEdit>
                  <RadioGroup
                     row
                     value={order.payer}
                     onChange={handleInputChange}
                     name="payer"
                  >
                     <FormControlLabel
                        value="true"
                        control={<Radio required disableRipple />}
                        label="Sender"
                     />
                     <FormControlLabel
                        value="false"
                        control={<Radio required disableRipple />}
                        label="Recipient"
                     />
                  </RadioGroup>
               </CardInfoEdit>
            </PayerBlock>
         </Card>
      </Container>
   )
}

EditableForm.propTypes = {
   order: PropTypes.shape({
      fullAddress: PropTypes.string,
      recipientsAddress: PropTypes.string,
      cityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      regionId: PropTypes.number,
      recipientsName: PropTypes.string,
      sendersNumbers: PropTypes.arrayOf(PropTypes.shape({})),
      recipientsNumbers: PropTypes.arrayOf(PropTypes.shape({})),
      sendersAddress: PropTypes.string,
      sendersName: PropTypes.string,
      tariff: PropTypes.shape({
         name: PropTypes.string,
      }),
      amountOfRansom: PropTypes.number,
      payer: PropTypes.bool,
      additionalInformation: PropTypes.string,
      createDate: PropTypes.string,
      paymentAmount: PropTypes.number,
      weight: PropTypes.string,
      city: PropTypes.shape({ prices: PropTypes.arrayOf(PropTypes.shape({})) }),
   }).isRequired,
   onChange: PropTypes.func.isRequired,
}

const ReadonlyForm = ({ order }) => {
   return (
      <Container>
         <Card>
            <ContentBlock>
               <ContentInner>
                  <CardTitle>Sender:</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <UserIcon />
                     </span>
                     <span>{order?.sendersName}</span>
                  </CardInfoEdit>
                  {order.sendersNumbers.map((number) => (
                     <CardInfoEdit key={number.id}>
                        <span>
                           <PhoneOrderCard />
                        </span>
                        <span>{number.num}</span>
                     </CardInfoEdit>
                  ))}
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <span>{order.sendersAddress}</span>
                  </CardInfoEdit>
               </ContentInner>
               <ContentInner>
                  <CardTitle>Recipient:</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <UserIcon />
                     </span>
                     <span>{order.recipientsName}</span>
                  </CardInfoEdit>
                  {order.recipientsNumbers.map((number) => (
                     <CardInfoEdit key={number.id}>
                        <span>
                           <PhoneOrderCard />
                        </span>
                        <span>{number.num}</span>
                     </CardInfoEdit>
                  ))}
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <span>{order.fullAddress}</span>
                  </CardInfoEdit>
               </ContentInner>
            </ContentBlock>
         </Card>
         <Card>
            <ContentBlock>
               <ContentInner>
                  <CardTitle>Delivery details</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <CarOrderIcon />
                     </span>
                     {order.tariff.name}
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <Price />
                     </span>
                     {order.amountOfRansom}
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <Flexer>
                        <span>
                           <ThemisIcon />
                        </span>
                        {WEIGHT_LABELS[order.weight]}
                        <span>
                           <MoneyIcon />
                        </span>
                        {order.paymentAmount}
                     </Flexer>
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <TimeOrderCard />
                     </span>
                     {order.createDate}
                  </CardInfoEdit>
               </ContentInner>
               <ContentInner>
                  <CardTitle>Order comment:</CardTitle>
                  <StyledComment>{order.additionalInformation}</StyledComment>
               </ContentInner>
            </ContentBlock>
            <PayerBlock>
               <CardTitle>The payment for the service is made by:</CardTitle>
               <CardInfoEdit>
                  <span>{order.payer ? 'Sender' : 'Recipient'}</span>
               </CardInfoEdit>
            </PayerBlock>
         </Card>
      </Container>
   )
}

ReadonlyForm.propTypes = {
   order: PropTypes.shape({
      fullAddress: PropTypes.string,
      recipientsAddress: PropTypes.string,
      cityId: PropTypes.number,
      regionId: PropTypes.number,
      recipientsName: PropTypes.string,
      sendersNumbers: PropTypes.arrayOf(PropTypes.shape({})),
      recipientsNumbers: PropTypes.arrayOf(PropTypes.shape({})),
      sendersAddress: PropTypes.string,
      sendersName: PropTypes.string,
      tariff: PropTypes.shape({
         name: PropTypes.string,
      }),
      amountOfRansom: PropTypes.number,
      payer: PropTypes.bool,
      additionalInformation: PropTypes.string,
      createDate: PropTypes.string,
      paymentAmount: PropTypes.number,
      weight: PropTypes.string,
   }).isRequired,
}
