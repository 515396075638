import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import CourierItem from './CourierItem'

const CouiersList = ({ couriers }) => {
   const navigate = useNavigate()
   const filteredCouriers = [...couriers].sort((a) => {
      return a.driverStatus === 'NOT_ASSIGNED' ? -1 : 1
   })

   const navigateToProfilePage = (id) => {
      navigate(`/dashboard/couriers/${id}`)
   }

   return filteredCouriers?.map((el) => {
      return (
         <CourierItem
            name={`${el.firstName} ${el.lastName}`}
            status={el.driverStatus}
            image={el.image || ''}
            hasCar={el.car}
            orders={el.orderCount}
            key={el.id}
            onClick={() => navigateToProfilePage(el.id)}
         />
      )
   })
}
CouiersList.propTypes = {
   couriers: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string,
         status: PropTypes.string,
         icon: PropTypes.shape({}),
         hasCar: PropTypes.bool,
      })
   ).isRequired,
}

export default CouiersList
