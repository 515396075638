import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiAlert from '@mui/material/Alert'
import NotificationErrorIcon from '../../../assets/icons/errorNotification.svg'
import NotificationSuccessIcon from '../../../assets/icons/NotificationIcon.svg'

const StyledAlert = styled(MuiAlert)`
   max-height: ${({ hasLongText }) => (hasLongText ? '20%' : '15%')};
   background: ${(props) =>
      props.variant === 'error' ? '#C91E1E' : '#36AC0C'};
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-style: normal;
   font-weight: 600;
   font-size: ${({ hasLongText }) => (hasLongText ? '14px' : '16px')};
   line-height: 22px;
   margin-top: ${({ hasLongText }) => (hasLongText ? '0' : '35px')};
   margin-bottom: 0;
   color: #ffffff;
   overflow-y: auto;
   &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
   }
`

const Container = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-right: 5px;
   gap: 10px;
`

const ICONS = {
   success: NotificationSuccessIcon,
   error: NotificationErrorIcon,
}

const Notification = forwardRef(({ message, id, ...props }, ref) => {
   const hasLongText = message.length > 180
   return (
      <StyledAlert hasLongText={hasLongText} icon={false} {...props} ref={ref}>
         <Container hasLongText={hasLongText}>
            {message}
            <img src={ICONS[props.variant]} alt="notification" />
         </Container>
      </StyledAlert>
   )
})

Notification.propTypes = {
   message: PropTypes.string.isRequired,
   id: PropTypes.number.isRequired,
   variant: PropTypes.string.isRequired,
}

export default Notification
