import axios from 'axios'
import {
   getStoragedItem,
   removeItemFromStorage,
} from '../utils/helpers/localStorageHelpers'
import { baseURL, JWT_TOKEN_KEY } from '../utils/constants/general'

const authToken = getStoragedItem(JWT_TOKEN_KEY)

const headers = {
   'Content-Type': 'multipart/form-data',
}

if (authToken) {
   headers.Authorization = `Bearer ${authToken}`
}

const formDataInstance = axios.create({
   baseURL,
   headers,
})

formDataInstance.interceptors.request.use(
   (config) => {
      return config
   },
   (error) => {
      return Promise.reject(error)
   }
)

formDataInstance.interceptors.response.use(
   (response) => {
      return Promise.resolve(response)
   },
   (error) => {
      if (error.response.status === 401) {
         removeItemFromStorage(JWT_TOKEN_KEY)
         throw new Error('401 unauthotized')
      }
      return Promise.reject(error)
   }
)

export default formDataInstance
