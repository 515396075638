import { parseISO } from 'date-fns'
import {
   DELIVERY_IS_LATE_DURATION_IN_MINUTES,
   STATUSES,
   TARIFF_TYPES,
   WORK_CARD_COLORS,
} from '../constants/general'
import {
   getDateTimeDifferenceInMinutes,
   getDifferenceInMinutes,
} from './dateTimeHelpers'

export const getErrorMessage = (error) => {
   if (
      error?.response &&
      error?.response?.data &&
      error?.response?.data?.message
   )
      return JSON.stringify(error.response.data.message)
   if (error?.response && error?.response?.data) {
      return JSON.stringify(error.response.data)
   }
   return "Server Doesn't Sent Error Message"
}

export const getOrderCardContainerStyles = (order) => {
   let currentState = 'DEFAULT'

   if ([TARIFF_TYPES.CITY, TARIFF_TYPES.CITY_EXPRESS].includes(order.type)) {
      if (
         ![
            STATUSES.IN_PROCESSING,
            STATUSES.CANCELED,
            STATUSES.DELIVERED,
         ].includes(order.status)
      ) {
         const deliveryDuration = getDifferenceInMinutes(order.createDate)
         const isExpressDeliveryLate =
            order.type === TARIFF_TYPES.CITY_EXPRESS &&
            deliveryDuration >=
               DELIVERY_IS_LATE_DURATION_IN_MINUTES.EXPRESS.ACTIVE
         const isCityDeliveryLate =
            order.type === TARIFF_TYPES.CITY &&
            deliveryDuration >= DELIVERY_IS_LATE_DURATION_IN_MINUTES.CITY.ACTIVE

         if (isExpressDeliveryLate || isCityDeliveryLate) {
            currentState = 'DELIVERY_IS_LATE'
         }
      }

      if (order.status === STATUSES.CANCELED) {
         const canceledStatus = order.orderStatus.find(
            (s) => s.orderStatus === STATUSES.CANCELED
         )
         if (canceledStatus) {
            const deliveryDuration = getDateTimeDifferenceInMinutes(
               parseISO(order.createDate),
               parseISO(canceledStatus.date)
            )
            const isExpressDeliveryLate =
               order.type === TARIFF_TYPES.CITY_EXPRESS &&
               deliveryDuration >=
                  DELIVERY_IS_LATE_DURATION_IN_MINUTES.EXPRESS.FINISHED
            const isCityDeliveryLate =
               order.type === TARIFF_TYPES.CITY &&
               deliveryDuration >=
                  DELIVERY_IS_LATE_DURATION_IN_MINUTES.CITY.FINISHED

            if (isExpressDeliveryLate || isCityDeliveryLate) {
               currentState = 'DELIVERY_IS_LATE'
            }
         }
      }

      if (order.status === STATUSES.DELIVERED) {
         const deliveredStatus = order.orderStatus.find(
            (s) => s.orderStatus === STATUSES.DELIVERED
         )

         if (deliveredStatus) {
            const deliveryDuration = getDateTimeDifferenceInMinutes(
               parseISO(order.createDate),
               parseISO(deliveredStatus.date)
            )
            const isExpressDeliveryLate =
               order.type === TARIFF_TYPES.CITY_EXPRESS &&
               deliveryDuration >=
                  DELIVERY_IS_LATE_DURATION_IN_MINUTES.EXPRESS.FINISHED
            const isCityDeliveryLate =
               order.type === TARIFF_TYPES.CITY &&
               deliveryDuration >=
                  DELIVERY_IS_LATE_DURATION_IN_MINUTES.CITY.FINISHED

            if (isExpressDeliveryLate || isCityDeliveryLate) {
               currentState = 'DELIVERY_IS_LATE'
            }
         }
      }
   }

   return WORK_CARD_COLORS[currentState]
}
