import axiosInstance from '../config/axios-instance'

export const getOrdersRequest = () => {
   return axiosInstance.get(`orders/notAssigned`)
}

export const createOrderRequest = (orderData) => {
   const params = {}
   if ('clientId' in orderData) params.clientId = orderData.clientId
   return axiosInstance.post('/orders', orderData, { params })
}

export const getOrderByIdRequest = (id) => {
   return axiosInstance.get(`/orders/get/${id}`)
}

export const editOrderRequest = (id, orderData) => {
   return axiosInstance.put(`/orders/${id}`, orderData)
}
export const cancelOrderRequest = (id) => {
   return axiosInstance.post(`/orders/driver/cancel`, { orderId: id })
}

export const deliverOrderRequest = (id, orderData) => {
   return axiosInstance.put(`/orders/delivered/${id}`, orderData)
}
