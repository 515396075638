import { useState } from 'react'
import { validateImage } from '../utils/helpers/imageHelpers'

export const useImageLoader = (companyIcon) => {
   const [imageLoaded, setImageLoaded] = useState(false)
   const isImageValid = validateImage(companyIcon)
   const loadingStyles = !imageLoaded
      ? {
           filter: 'blur(10px)',
           clipPath: 'circle(50%)',
        }
      : {
           filter: 'blur(0px)',
           transition: 'filter 0.5s linear',
        }

   return { setImageLoaded, isImageValid, loadingStyles }
}
