import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   isLoading: false,
   tariffs: [],
}

const tariffSlice = createSlice({
   name: 'tarifSlice',
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      getTariffs(state, { payload }) {
         state.isLoading = false
         state.tariffs = payload.tariffs
      },
      deleteTariff(state, { payload }) {
         state.isLoading = false
         state.tariffs = state.tariffs.filter(
            (tariff) => tariff.id !== payload.id
         )
      },
   },
})

export const tariffActions = tariffSlice.actions

export default tariffSlice
