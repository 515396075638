import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ROUTES } from '../utils/constants/general'

function PrivateAuthRoute({
   component: RouteComponent,
   fallbackPath,
   isAllowed,
}) {
   if (!isAllowed) {
      return <Navigate to={fallbackPath} replace />
   }

   return RouteComponent
}

PrivateAuthRoute.propTypes = {
   component: PropTypes.element,
   fallbackPath: PropTypes.string,
   isAllowed: PropTypes.bool.isRequired,
}

PrivateAuthRoute.defaultProps = {
   component: PropTypes.element,
   fallbackPath: ROUTES.LOGIN,
}

export default PrivateAuthRoute
