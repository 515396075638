import { format } from 'date-fns'
import axiosInstance from '../config/axios-instance'

export const createCourierRequest = (courierData) => {
   return axiosInstance.post(`/drivers`, courierData)
}

export const getCouriersRequest = (name, pageIndex, size) => {
   const params = { size: 6 }
   if (name) {
      params.name = name
   }
   if (pageIndex) {
      params.page = pageIndex
   }
   if (size) {
      params.size = size
   }
   return axiosInstance.get(`/drivers`, { params })
}

export const editCourierRequest = (courierId, courierData) => {
   return axiosInstance.put(`/drivers/${courierId}`, courierData)
}

export const getCourierByIdRequest = (courierId) => {
   return axiosInstance.get(`/drivers/${courierId}/profile`)
}

export const deleteCourierRequest = (courierId) => {
   return axiosInstance.delete(`/drivers/delete/${courierId}`)
}

export const getCommentsRequest = (courierId, pageIndex) => {
   const params = {}
   if (pageIndex) {
      params.page = pageIndex
      params.size = 5
   }
   return axiosInstance.get(`comments/driver/${courierId}`, { params })
}
export const blockCourierRequest = (courierId) => {
   return axiosInstance.put(`/drivers/block/${courierId}`)
}

export const unlockCourierRequest = (courierId) => {
   return axiosInstance.put(`/drivers/unBlock/${courierId}`)
}

export const getCourierHistoryRequest = (courierId) => {
   return axiosInstance.put(`/drivers/history/${courierId}`)
}

export const getOnlineCouriersRequest = (orderId) => {
   return axiosInstance.get(`/drivers/online/${orderId}`)
}

export const getOrdersHistoryRequest = (
   courierId,
   startDate,
   endDate,
   pageIndex
) => {
   const params = {}

   if (startDate) {
      params.startDate = format(startDate, 'yyyy-MM-dd')
   }

   if (startDate) {
      if (!endDate) {
         params.endDate = format(new Date(), 'yyyy-MM-dd')
      }
   }
   if (endDate) {
      if (!startDate) {
         params.startDate = format(new Date(), 'yyyy-MM-dd')
      }
   }

   if (endDate) {
      params.endDate = format(endDate, 'yyyy-MM-dd')
   }
   if (courierId) {
      params.driverId = courierId
   }
   if (pageIndex) {
      params.page = pageIndex
      params.size = 4
   }
   return axiosInstance.get(`/orders/driver/history`, { params })
}

export const getCourierHistoryPdfRequest = (
   courierId,
   startDate,
   endDate,
   pageIndex
) => {
   const params = {}
   if (startDate) {
      params.startDate = startDate
   }
   if (endDate) {
      params.endDate = endDate
   }
   if (courierId) {
      params.driverId = courierId
   }
   if (pageIndex) {
      params.page = pageIndex
      params.size = 20
   }
   return axiosInstance.get(`/drivers/orders/history/export/pdf`, { params })
}

export const getCourierActiveWorksRequest = (courierId) => {
   const params = {
      driverId: courierId,
      page: 1,
      size: 200,
   }
   return axiosInstance.get(`/orders/driver/work`, { params })
}
