import axiosInstance from '../config/axios-instance'

export const getMastersRequest = (companyId) => {
   const params = {}
   if (companyId) {
      params.companyId = companyId
   }
   return axiosInstance.get(`/officeUser`, { params })
}

export const getMasterByIdRequest = (id) => {
   return axiosInstance.get(`/officeUser/${id}`)
}

export const deleteMasterRequest = (id) => {
   return axiosInstance.delete(`/officeUser/${id}`)
}

export const createMasterRequest = (masterData) => {
   return axiosInstance.post('/officeUser/create', masterData)
}

export const editMasterRequest = (id, masterData) => {
   return axiosInstance.put(`/officeUser/${id}`, masterData)
}

export const blockMasterRequest = (id) => {
   return axiosInstance.put(`/officeUser/block/${id}`)
}

export const unlockMasterRequest = (id) => {
   return axiosInstance.put(`/officeUser/unBlock/${id}`)
}

export const getOfficeUserProfileRequest = () => {
   return axiosInstance.get(`/officeUser/profile`)
}
