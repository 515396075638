import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ReactComponent as PhoneOrderCard } from '../../../../assets/icons/phoneOrderCard.svg'
import { ReactComponent as UserIcon } from '../../../../assets/icons/UserOfficeUser.svg'
import { ReactComponent as UserAddress } from '../../../../assets/icons/addressOrderCard.svg'
import { ReactComponent as Price } from '../../../../assets/icons/priceOfficeUser.svg'
import { ReactComponent as CarOrderIcon } from '../../../../assets/icons/CarOrderCard.svg'
import { ReactComponent as TimeOrderCard } from '../../../../assets/icons/TimeOrderCard.svg'
import { ReactComponent as MoneyIcon } from '../../../../assets/icons/moneyIcon.svg'

import Button from '../../../../components/UI/Button'
import CustomBreadcrumbs from '../../../../components/UI/Breadcrumbs'
import useEnqueueSnackbar from '../../../../components/UI/Notification/useEnqueueSnackbar'
import { getOrderByIdRequest } from '../../../../api/orderService'
import { formatDate } from '../../../../utils/helpers/dateTimeHelpers'
import Input from '../../../../components/UI/Input'
import DynamicPhoneInput from '../../../../components/UI/DynamicPhoneInput'
import { deliverOrder, editOrder } from '../../../../store/order/orderActions'
import { getOnlineCouriers } from '../../../../store/courier/courierAction'
import { getMasterTariffs } from '../../../../store/tariff/tariffActions'
import {
   convertDriverNumbers,
   getDriver,
   getDriverStatus,
   getTariffsFromStore,
   ORDER_STATUSES,
} from '../../../../utils/constants/general'
import { MASTER_EDITABLE_STATUSES } from '../../../../utils/constants/order-constants'
import {
   Actions,
   Card,
   CardInfoEdit,
   CardTitle,
   ContentBlock,
   ContentInner,
   DriverCommentInput,
   OrderNums,
   PayerBlock,
   StyledSelect,
} from '../../../../components/UI/OrderUI'
import StatusSelect from '../../../../components/UI/StatusSelect/StatusSelect'

export default function EditWork() {
   const [order, setOrder] = useState({
      barcode: '',
      recipientsNumbers: [],
      recipientsAddress: '',
      recipientsName: '',
      sendersAddress: '',
      sendersNumbers: [],
      additionalInformation: '',
      createDate: '',
      id: '',
      regionId: 0,
      amountOfRansom: '',
      sendersName: '',
      tariffName: '',
      firstDriver: {},
      commentDriver: '',
      tariff: { name: '', price: 0 },
      canEdit: { data: false, courier: false },
      payer: '',
      status: '',
      currentStatus: '',
      paymentAmount: '',
   })

   const { onlineCouriers: couriers } = useSelector((state) => state.courier)
   const tariffs = useSelector((state) =>
      getTariffsFromStore(state, order.tariff.name)
   )

   const { orderId } = useParams()
   const notify = useEnqueueSnackbar()
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const getOrderById = async () => {
      try {
         const { data } = await getOrderByIdRequest(orderId)
         const recipientsNumbers = convertDriverNumbers(data.recipientsNumbers)
         const sendersNumbers = convertDriverNumbers(data.sendersNumbers)

         dispatch(getOnlineCouriers(notify, orderId))

         const isMasterCanEdit = (order) => {
            if (MASTER_EDITABLE_STATUSES.includes(order.status)) {
               return { data: true, courier: true }
            }
            return { data: false, courier: false }
         }

         setOrder({
            ...data,
            firstDriver: getDriver(data.drivers.first),
            tariffId: data.tariff.id,
            recipientsNumbers,
            sendersNumbers,
            createDate: formatDate(data.createDate),
            canEdit: isMasterCanEdit(data),
            hasDrivers: {
               firstDriver: Boolean(data.drivers.first),
               secondDriver: Boolean(data.drivers.second),
            },
            check: {
               firstDriver: getDriver(data.drivers.first),
               secondDriver: getDriver(data.drivers.second),
            },
            currentStatus: data.status,
         })
      } catch (error) {
         notify('Failed to get order data', 'error')
      }
   }

   useEffect(() => getOrderById(), [])
   useEffect(() => dispatch(getMasterTariffs(notify)), [])

   const handleInputChange = ({ target: { name, value } }) => {
      setOrder({ ...order, [name]: value })
   }

   const handleTariffChange = ({ target: { name, value } }) => {
      const foundTariff = tariffs.find(({ id }) => id === value)
      setOrder({
         ...order,
         [name]: foundTariff,
         tariffId: foundTariff.id,
         paymentAmount: foundTariff.price,
      })
   }

   const handleStatusChange = ({ target: { name, value } }) => {
      setOrder({ ...order, [name]: value })
   }

   const handleCourierChange = ({ target: { name, value } }) => {
      const { car, ...other } = couriers.find(({ id }) => id === value)
      setOrder({
         ...order,
         [name]: {
            ...other,
            car: car ? 'Yes' : 'No',
            regionName: order.firstDriver.regionName,
            driverChangeStatus: 'CHANGED',
         },
      })
   }
   const handleChangeNumbers = ({ target: { name, value } }, index) => {
      const updatedValues = { ...order }
      updatedValues[name][index].num = value
      setOrder(updatedValues)
   }

   const addSecondNum = (name) => {
      return () => {
         if (order[name].length > 1) return
         const updatedValues = { ...order }
         updatedValues[name].push({ num: '', id: Math.random().toString() })
         setOrder(updatedValues)
      }
   }

   const removeSecondNum = (name, id) => {
      return () => {
         setOrder({
            ...order,
            [name]: order[name].filter((num) => num.id !== id),
         })
      }
   }

   const navigateToOrder = () => navigate(-1)

   const formSubmitHandler = (e) => {
      e.preventDefault()
      const updated = {
         ...order,
         sendersNumbers: order.sendersNumbers.map(({ num }) => num),
         recipientsNumbers: order.recipientsNumbers.map(({ num }) => num),
         payer: order.payer === 'true',
         drivers: { first: getDriverStatus(order, 'firstDriver') },
         type: order.tariff.type,
      }
      if (order.currentStatus === 'DELIVERED') {
         return dispatch(
            deliverOrder(order.id, updated, navigateToOrder, notify)
         )
      }

      return dispatch(editOrder(orderId, updated, notify, navigateToOrder))
   }

   const paths = {
      works: 'Works',
      edit: 'Edit',
      [orderId]: `#${orderId}`,
   }

   return (
      <form onSubmit={formSubmitHandler}>
         <CustomBreadcrumbs pathTranslate={paths} />
         <Header>
            <div>
               <OrderNums>
                  Order number: <span>#{order.id}</span>
               </OrderNums>
               <OrderNums last>
                  Barcode number: <span>{order.barcode || '-------'}</span>
               </OrderNums>
            </div>
            <div>
               <StatusSelect
                  options={[
                     {
                        label: ORDER_STATUSES[order.status]?.text,
                        id: order.status,
                     },
                     { label: 'Delivered', id: 'DELIVERED' },
                  ]}
                  name="currentStatus"
                  onChange={handleStatusChange}
                  value={order.currentStatus}
               />
            </div>
         </Header>
         <Card>
            <ContentBlock>
               <ContentInner>
                  <CardTitle>Sender:</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <UserIcon />
                     </span>
                     {order.canEdit.data ? (
                        <Input
                           required
                           label="Sender's name"
                           name="sendersName"
                           onChange={handleInputChange}
                           value={order.sendersName}
                        />
                     ) : (
                        <span>{order?.sendersName}</span>
                     )}
                  </CardInfoEdit>
                  {order.sendersNumbers.map((number, index) => (
                     <CardInfoEdit key={number.id}>
                        <span>
                           <PhoneOrderCard />
                        </span>
                        <DynamicPhoneInput
                           key={number.id}
                           data={number}
                           order={index}
                           name="sendersNumbers"
                           onChange={handleChangeNumbers}
                           addSecondNum={addSecondNum}
                           removeSecondNum={removeSecondNum}
                        />
                     </CardInfoEdit>
                  ))}
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <Input
                        required
                        label="Sender's address"
                        name="sendersAddress"
                        onChange={handleInputChange}
                        value={order.sendersAddress}
                     />
                  </CardInfoEdit>
               </ContentInner>
               <ContentInner>
                  <CardTitle>Recipient:</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <UserIcon />
                     </span>
                     <Input
                        required
                        label="Recipient's name"
                        name="recipientsName"
                        onChange={handleInputChange}
                        value={order.recipientsName}
                     />
                  </CardInfoEdit>
                  {order.recipientsNumbers.map((number, index) => (
                     <CardInfoEdit key={number.id}>
                        <span>
                           <PhoneOrderCard />
                        </span>
                        <DynamicPhoneInput
                           key={number.id}
                           data={number}
                           order={index}
                           name="recipientsNumbers"
                           onChange={handleChangeNumbers}
                           removeSecondNum={removeSecondNum}
                           addSecondNum={addSecondNum}
                        />
                     </CardInfoEdit>
                  ))}
                  <CardInfoEdit>
                     <span>
                        <UserAddress />
                     </span>
                     <Input
                        required
                        label="Recipient's name"
                        name="recipientsAddress"
                        onChange={handleInputChange}
                        value={order.recipientsAddress || ''}
                     />
                  </CardInfoEdit>
               </ContentInner>
            </ContentBlock>
         </Card>
         <Card>
            <ContentBlock>
               <ContentInner>
                  <CardTitle>Delivery details</CardTitle>
                  <CardInfoEdit>
                     <span>
                        <CarOrderIcon />
                     </span>
                     <StyledSelect
                        required
                        name="tariff"
                        label="Delivery type"
                        options={tariffs}
                        value={order.tariff.id}
                        onChange={handleTariffChange}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                     />
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <MoneyIcon />
                     </span>
                     <Input
                        name="paymentAmount"
                        label="Cost"
                        value={order.paymentAmount}
                        onChange={handleInputChange}
                     />
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <Price />
                     </span>
                     {order.amountOfRansom}
                  </CardInfoEdit>
                  <CardInfoEdit>
                     <span>
                        <TimeOrderCard />
                     </span>
                     {order.createDate}
                  </CardInfoEdit>
               </ContentInner>
               <ContentInner>
                  <CardTitle>Comment:</CardTitle>
                  <DriverCommentInput
                     multiline
                     rows={6}
                     name="additionalInformation"
                     onChange={handleInputChange}
                     value={order.additionalInformation}
                  />
               </ContentInner>
            </ContentBlock>
            <PayerBlock>
               <CardTitle>Pays for service makes:</CardTitle>
               <CardInfoEdit>
                  {order.canEdit.data ? (
                     <RadioGroup
                        row
                        value={order.payer}
                        onChange={handleInputChange}
                        name="payer"
                     >
                        <FormControlLabel
                           value="true"
                           control={<Radio required disableRipple />}
                           label="Sender"
                        />
                        <FormControlLabel
                           value="false"
                           control={<Radio required disableRipple />}
                           label="Recipient"
                        />
                     </RadioGroup>
                  ) : (
                     <span>{order.payer ? 'Sender' : 'Recipient'}</span>
                  )}
               </CardInfoEdit>
            </PayerBlock>
         </Card>
         <Card>
            <ContentBlock>
               <ContentInner>
                  <div>
                     <CardTitle>
                        Courier({order.firstDriver.regionName || ''}):
                     </CardTitle>
                     <CardInfoEdit>
                        <span>
                           <UserIcon />
                        </span>
                        <StyledSelect
                           required
                           name="firstDriver"
                           label="Choose  a courier"
                           options={couriers}
                           value={order.firstDriver?.id}
                           onChange={handleCourierChange}
                           getOptionLabel={(option) => option.firstName}
                           getOptionValue={(option) => option.id}
                        />
                     </CardInfoEdit>
                     <CardInfoEdit>
                        <span>
                           <PhoneOrderCard />
                        </span>
                        {order.firstDriver.phone}
                     </CardInfoEdit>
                     <CardInfoEdit>
                        <span>
                           <CarOrderIcon />
                        </span>
                        {order.firstDriver.car}
                     </CardInfoEdit>
                  </div>
               </ContentInner>
               <ContentInner>
                  <div>
                     <CardTitle>Will be in (On the way):</CardTitle>
                     <DriverCommentInput
                        name="commentDriver"
                        value={order.commentDriver}
                        onChange={handleInputChange}
                        multiline
                        rows={6}
                     />
                  </div>
               </ContentInner>
            </ContentBlock>
         </Card>
         <Actions>
            <Button variant="outlined" onClick={navigateToOrder}>
               Cancel
            </Button>
            <Button variant="contained" type="submit">
               Save
            </Button>
         </Actions>
      </form>
   )
}

const Header = styled('div')`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 60px;
`
