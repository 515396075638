import { getCitiesRequest, getMasterCitiesRequest } from '../../api/cityService'
import { cityActions } from './citySlice'
import { getErrorMessage } from '../../utils/helpers/general'

// getAll Citys
export const getCities = (regionId, notify) => {
   return async (dispatch) => {
      try {
         if (!regionId) return null
         dispatch(cityActions.setIsLoading(true))
         const { data: cities } = await getCitiesRequest(regionId)
         return dispatch(cityActions.setCities({ cities }))
      } catch (e) {
         notify(`Something went wrong ${getErrorMessage(e)}`, 'error')
         return dispatch(cityActions.setIsLoading(false))
      }
   }
}

export const getMasterCities = (regionId, notify) => {
   return async (dispatch) => {
      try {
         if (!regionId) return null
         dispatch(cityActions.setIsLoading(true))
         const { data: cities } = await getMasterCitiesRequest(regionId)
         return dispatch(cityActions.setCities({ cities }))
      } catch (e) {
         notify(`Something went wrong ${getErrorMessage(e)}`, 'error')
         return dispatch(cityActions.setIsLoading(false))
      }
   }
}
