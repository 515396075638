import MuiButton from '@mui/material/Button'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ButtonSpinner from './ButtonSpinner'

const BTN_SIZE_SETTINGS = {
   small: '12px',
   medium: '14px',
   large: '16px',
}

const StyledButtonSpinner = styled(ButtonSpinner)`
   margin-right: 16px;
   color: #1f6ed4;
`

const Button = (props) => {
   const { size, fullWidth, children, isLoading, ...otherProps } = props

   return (
      <StyledButton {...otherProps} size={size} fullWidth={fullWidth}>
         {isLoading && <StyledButtonSpinner size={14} />}
         {children}
      </StyledButton>
   )
}

export default Button

const StyledButton = styled(({ fullWidth, size, ...otherProps }) => (
   <MuiButton {...otherProps} />
))`
   padding: 10px 15px;
   border-radius: 10px;
   font-weight: 400;
   font-size: 16px;
   line-height: 22px;
   text-transform: none;
   & > .MuiButton-startIcon > *:nth-of-type(1) {
      font-size: 18px;
   }
   &.MuiButton-root {
      &.Mui-disabled {
         background-color: #1f6ed466;
         color: #ffffff;
      }
   }
   font-size: ${(props) => BTN_SIZE_SETTINGS[props.size]};
   ${(props) => (props.fullWidth ? 'width: 100%' : '')};
`
Button.propTypes = {
   size: PropTypes.oneOf(['small', 'medium', 'large']),
   fullWidth: PropTypes.bool,
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
   ]).isRequired,
   isLoading: PropTypes.bool,
}

Button.defaultProps = {
   size: 'medium',
   fullWidth: false,
   isLoading: false,
}
