import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Index from '../containers/master/Works/EditWork/Index'
import { ROLES, ROUTES } from '../utils/constants/general'
import PrivateRouteProtectedByRole from './PrivateRouteProtectedByRole'

const Masters = lazy(() => import('../containers/host/Masters/Masters'))
const Orders = lazy(() => import('../containers/master/Orders/Orders'))
const Works = lazy(() => import('../containers/master/Works/Works'))
const WorkHistory = lazy(() => import('../containers/master/Works/WorkHistory'))
const Couriers = lazy(() => import('../containers/master/Couriers'))
const Company = lazy(() => import('../containers/host/Company/index'))
const Branches = lazy(() => import('../containers/host/Branches/Branches'))
const Tariffs = lazy(() => import('../containers/host/Tariffs/Tariffs'))
const Regions = lazy(() => import('../containers/host/Regions/Regions'))
const CourierProfile = lazy(() =>
   import('../containers/master/Couriers/CourierProfile')
)
const HistoryInner = lazy(() =>
   import('../containers/master/Couriers/HistoryInner/HistoryInner')
)
const OrderInner = lazy(() => import('../containers/master/Orders/OrderInner'))
const HostOrders = lazy(() => {
   return import('../containers/host/HostOrders/HostOrders')
})

const OfficeUserProfile = lazy(() => import('../layout/OfficeUserProfile'))
const WorkInner = lazy(() => import('../containers/master/Works/WorkInner'))
const EditOrder = lazy(() => import('../containers/master/Orders/EditOrder'))
const JuridicalEntities = lazy(() =>
   import('../containers/host/JuridicalEntities/JuridicalEntities')
)

const JuridicalEntitiesReport = lazy(() =>
   import(
      '../containers/master/juridicalEntities/report/JuridicalEntitiesReport'
   )
)
const JuridicalEntitiesReportDetails = lazy(() =>
   import(
      '../containers/master/juridicalEntities/reportDetails/JuridicalEntitiesReportDetails'
   )
)

const DashboardRoutes = () => {
   return (
      <Routes>
         <Route
            path={ROUTES.WORKEDIT}
            element={
               <PrivateRouteProtectedByRole
                  component={<Index />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.DETAILSORDER}
            element={
               <PrivateRouteProtectedByRole
                  component={<HistoryInner />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.COMPANY}
            element={
               <PrivateRouteProtectedByRole
                  component={<Company />}
                  roles={[ROLES.ROLE_HOST]}
               />
            }
         />
         <Route
            path={ROUTES.MASTERS}
            element={
               <PrivateRouteProtectedByRole
                  component={<Masters />}
                  roles={[ROLES.ROLE_HOST]}
               />
            }
         />
         <Route
            path={ROUTES.ORDERS}
            element={
               <PrivateRouteProtectedByRole
                  component={<Orders />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.ORDEREDIT}
            element={
               <PrivateRouteProtectedByRole
                  component={<EditOrder />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.COURIERS}
            element={
               <PrivateRouteProtectedByRole
                  component={<Couriers />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.BRANCHES}
            element={
               <PrivateRouteProtectedByRole
                  component={<Branches />}
                  roles={[ROLES.ROLE_HOST]}
               />
            }
         />
         <Route
            path={ROUTES.TARIFFS}
            element={
               <PrivateRouteProtectedByRole
                  component={<Tariffs />}
                  roles={[ROLES.ROLE_HOST]}
               />
            }
         />
         <Route
            path={ROUTES.REGIONS}
            element={
               <PrivateRouteProtectedByRole
                  component={<Regions />}
                  roles={[ROLES.ROLE_HOST]}
               />
            }
         />
         <Route
            path={ROUTES.HOSTORDERS}
            element={
               <PrivateRouteProtectedByRole
                  component={<HostOrders />}
                  roles={[ROLES.ROLE_HOST]}
               />
            }
         />
         <Route
            path={ROUTES.COURIERPROFILE}
            element={
               <PrivateRouteProtectedByRole
                  component={<CourierProfile />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={`${ROUTES.ORDERINNER}`}
            element={
               <PrivateRouteProtectedByRole
                  component={<OrderInner />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.WORKS}
            element={
               <PrivateRouteProtectedByRole
                  component={<Works />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.WORKS_HISTORY}
            element={
               <PrivateRouteProtectedByRole
                  component={<WorkHistory />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.WORKINNER}
            element={
               <PrivateRouteProtectedByRole
                  component={<WorkInner />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.OFFICEUSER_PROFILE}
            element={
               <PrivateRouteProtectedByRole
                  component={<OfficeUserProfile />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.JURIDICAL_ENTITIES}
            element={
               <PrivateRouteProtectedByRole
                  component={<JuridicalEntities />}
                  roles={[ROLES.ROLE_HOST]}
               />
            }
         />
         <Route
            path={ROUTES.JURIDICAL_ENTITIES_REPORT}
            element={
               <PrivateRouteProtectedByRole
                  component={<JuridicalEntitiesReport />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
         <Route
            path={ROUTES.JURIDICAL_ENTITIES_REPORT_DETAILS}
            element={
               <PrivateRouteProtectedByRole
                  component={<JuridicalEntitiesReportDetails />}
                  roles={[ROLES.ROLE_MASTER]}
               />
            }
         />
      </Routes>
   )
}
export default DashboardRoutes
