import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ROUTES } from '../utils/constants/general'
import LogIn from '../containers/public/LogIn'
import LayoutOfDashboard from '../layout/Dashboard/LayoutOfDashboard'
import PrivateAuthRoute from './PrivateAuthRoute'

const OrderComment = lazy(() =>
   import('../containers/public/PublicPage/OrderComment')
)
const PublicPage = lazy(() =>
   import('../containers/public/PublicPage/PublicPage')
)

const AppRoutes = ({ isAuthorized }) => {
   return (
      <div>
         <Routes>
            <Route
               index
               path={ROUTES.LOGIN}
               element={
                  <PrivateAuthRoute
                     component={<LogIn />}
                     isAllowed={!isAuthorized}
                     fallbackPath={ROUTES.DASHBOARD}
                  />
               }
            />
            <Route
               path={`${ROUTES.DASHBOARD}/*`}
               element={
                  <PrivateAuthRoute
                     component={<LayoutOfDashboard />}
                     isAllowed={isAuthorized}
                     fallbackPath={ROUTES.LOGIN}
                  />
               }
            />
            <Route path={ROUTES.PUBLICPAGE} element={<PublicPage />} />
            <Route path={ROUTES.COMMENT} element={<OrderComment />} />
         </Routes>
      </div>
   )
}

AppRoutes.propTypes = {
   isAuthorized: PropTypes.bool.isRequired,
}

export default AppRoutes
