import {
   createReqionRequest,
   deleteRegionRequest,
   editExistingReqionRequest,
   getBranchRegionsRequest,
   getRegionsRequest,
} from '../../api/regionsService'
import { regionActions } from './regionSlice'
import { getErrorMessage } from '../../utils/helpers/general'
import { companyActions } from '../company/companySlice'

export const getRegions = (notify) => {
   return async (dispatch) => {
      try {
         const response = await getRegionsRequest()
         const regions = response.data

         dispatch(
            regionActions.getRegions({
               regions,
            })
         )
      } catch (error) {
         dispatch(regionActions.setLoading({ isLoading: false }))
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const getBranchRegions = (notify) => {
   return async (dispatch) => {
      try {
         dispatch(regionActions.setLoading({ isLoading: true }))
         const { data: regions } = await getBranchRegionsRequest()

         dispatch(regionActions.getRegions({ regions }))
      } catch (error) {
         dispatch(regionActions.setLoading({ isLoading: false }))
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const editRegion = (id, regionData, notify, closeModalAfterRequest) => {
   return async (dispatch) => {
      try {
         dispatch(regionActions.setLoading({ isLoading: true }))
         await editExistingReqionRequest(regionData)
         await dispatch(getBranchRegions(notify))
         closeModalAfterRequest()
      } catch (error) {
         dispatch(regionActions.setLoading({ isLoading: false }))
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const createRegion = (regionData, notify, closeModalAfterRequest) => {
   return async (dispatch) => {
      try {
         dispatch(regionActions.setLoading({ isLoading: true }))
         await createReqionRequest(regionData)
         await dispatch(getBranchRegions(notify))
         closeModalAfterRequest()
      } catch (error) {
         console.log(error.response)
         dispatch(regionActions.setLoading({ isLoading: false }))
         // notify(`Something went wrong ${error.message}`, 'error')
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const deleteRegion = (id, notifyFunction, closeDeleteModal) => {
   return async (dispatch) => {
      try {
         dispatch(companyActions.setLoading({ isLoading: true }))
         await deleteRegionRequest(id)
         await dispatch(getBranchRegions(notifyFunction))
         notifyFunction('Region deleted successfully', 'success')
         closeDeleteModal()
      } catch (error) {
         notifyFunction(
            `Something went wrong ${getErrorMessage(error)}`,
            'error'
         )
         dispatch(
            companyActions.setLoading({
               isLoading: false,
            })
         )
         closeDeleteModal()
      }
   }
}
