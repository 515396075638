import { createSlice } from '@reduxjs/toolkit'

const urlParams = new URLSearchParams(window.location.search)

const courierState = {
   isCreateLoading: false,
   isLoading: false,
   couriers: [],
   sideDrawerCouriers: [],
   onlineCouriers: [],
   orderHistory: [],
   activeWorks: [],
   totalPage: 1,
   totalPageHistory: 1,
   totalCount: 0,
   totalAmount: 0,
   page: JSON.parse(urlParams.get('page')) || 1,
}

const courierSlice = createSlice({
   name: 'courier',
   initialState: courierState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      setCreateLoading(state, { payload }) {
         state.isCreateLoading = payload.isLoading
      },
      createCourier(state) {
         state.isCreateLoading = false
      },
      getCouriers(state, { payload }) {
         state.isLoading = false
         state.couriers = payload.couriers
         state.totalPage = payload.totalPage
         state.totalCount = payload.totalCount
      },
      deleteCourier(state, { payload }) {
         state.isLoading = false
         state.couriers = state.couriers.filter(
            (courier) => courier.id !== payload.id
         )
      },

      getOnlineCouriers(state, { payload }) {
         state.isLoading = false
         state.onlineCouriers = payload.couriers
      },
      getOrderHistory(state, { payload }) {
         state.isLoading = false
         state.orderHistory =
            payload.orderData.orderHistoryResponse?.orders || []
         state.totalCount = payload.orderData.totalCount
         state.totalPageHistory = payload.orderData.totalPage
         state.totalAmount = payload.orderData.orderHistoryResponse.totalAmount
      },
      setPage(state, { payload }) {
         state.page = payload
      },
      getSideDrawerCouriers(state, { payload }) {
         state.sideDrawerCouriers = payload
         state.isLoading = false
      },
      setActiveWorks(state, { payload }) {
         state.isLoading = false
         state.activeWorks = payload
      },
   },
})
export const courierActions = courierSlice.actions
export default courierSlice
