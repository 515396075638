import React, { useEffect, useState } from 'react'
import { SwipeableDrawer } from '@material-ui/core'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import { ReactComponent as CloseDrawerIcon } from '../../assets/icons/CLoseDrawerIcon.svg'
import ProfilIcon from '../../assets/icons/ProfilMaster.svg'
import Logout from '../../assets/icons/Logout.svg'
import ConfirmModal from '../../components/UI/ConfirmModal'
import CouiersList from './CouiersList'
import { getSideDrawerCouriers } from '../../store/courier/courierAction'
import { logOut } from '../../store/user/userActions'
import useEnqueueSnackbar from '../../components/UI/Notification/useEnqueueSnackbar'

const MobileDrawerForCouries = ({ isOpen, onClose, onOpen }) => {
   const notify = useEnqueueSnackbar()
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { sideDrawerCouriers } = useSelector((state) => state.courier)

   useEffect(() => {
      const timer = setInterval(() => {
         dispatch(getSideDrawerCouriers(notify))
      }, 5000)
      return () => clearInterval(timer)
   }, [])

   useEffect(() => {
      dispatch(getSideDrawerCouriers(notify))
   }, [])

   const [isLogOutConfirmModalVisible, setIsLogOutConfirmModalVisible] =
      useState(false)
   const openLogOutConfirmModal = () => setIsLogOutConfirmModalVisible(true)
   const closeLogOutConfirmModal = () => setIsLogOutConfirmModalVisible(false)

   const logOutHandler = () => {
      dispatch(logOut())
      closeLogOutConfirmModal()
      onClose()
   }
   const navigateToOfficeUserProfile = () => {
      onClose()
      navigate(`/dashboard/profile`)
   }

   const toggleDrawer = (open) => (event) => {
      if (
         event &&
         event.type === 'keydown' &&
         (event.key === 'Tab' || event.key === 'Shift')
      ) {
         return
      }

      if (open) {
         onOpen()
      }

      onClose()
   }

   return (
      <StyledSwipeableDrawer
         anchor="right"
         open={isOpen}
         onClose={toggleDrawer(false)}
         onOpen={toggleDrawer(true)}
      >
         <CloseDrawerIconContainer>
            <IconButton onClick={toggleDrawer(false)}>
               <CloseDrawerIcon />
            </IconButton>
         </CloseDrawerIconContainer>

         <div>
            <StyledMenuItem onClick={navigateToOfficeUserProfile}>
               <img src={ProfilIcon} alt="Logout" />
               Profile
            </StyledMenuItem>
            <StyledBorderBottom />
            <StyledMenuItem onClick={openLogOutConfirmModal}>
               <img src={Logout} alt="Logout" />
               Exit
            </StyledMenuItem>
         </div>

         <Container>
            <ContentAvatar>
               <HeaderTitle>Courier list</HeaderTitle>
            </ContentAvatar>

            <ConfirmModal
               open={isLogOutConfirmModalVisible}
               onClose={closeLogOutConfirmModal}
               onConfirm={logOutHandler}
               title="Are you sure you want to exit?"
               confirmButtonTitle="Exit"
            />
            <CouriersListContainer>
               <CouiersList couriers={sideDrawerCouriers} />
            </CouriersListContainer>
         </Container>
      </StyledSwipeableDrawer>
   )
}

MobileDrawerForCouries.propTypes = {
   isOpen: PropTypes.bool.isRequired,
   onOpen: PropTypes.func.isRequired,
   onClose: PropTypes.func.isRequired,
}

export default MobileDrawerForCouries

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
   & .MuiDrawer-paper {
      width: 85%;
      background-color: #f5f6ff;
   }
`

const CloseDrawerIconContainer = styled.div`
   margin-top: 16px;
   margin-right: 16px;
   display: flex;
   width: 100%;
   justify-content: flex-end;
`

const Container = styled.div`
   background: #f5f6ff;
   height: 100%;
   padding-left: 16px;
   padding-right: 16px;
`

// const Avatar = styled.div`
//    background: #f5f6ff;
//    width: 40px;
//    height: 40px;
//    border-radius: 50%;
//    margin-right: 10px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    border: 1px solid #c4c4c4;
// `
const HeaderTitle = styled.div`
   font-weight: 400;
   font-size: 18px;
   line-height: 25px;
   color: #232323;
   margin-right: 10px;
`
const ContentAvatar = styled.div`
   display: flex;
   align-items: center;
   padding-bottom: 25px;
   padding-top: 27px;
`

const StyledBorderBottom = styled.div`
   border-bottom: 1px solid #ececec;
   width: 119px;
   margin-left: 20px;
`
const StyledMenuItem = styled(MenuItem)`
   font-weight: 600;
   font-size: 16px;
   line-height: 25px;
   color: #000000;
   width: 160px;
   transition: all 0.1s ease;
   background: #f5f6ff;
   &:hover {
      background-color: #f5f6ff;
   }
   img {
      width: 19px;
      height: 19px;
      margin-left: 8px;
      margin-right: 13px;
   }
`

const CouriersListContainer = styled.div`
   overflow-y: scroll;
   //height: 70vh;
`
