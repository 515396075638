import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'

export const formatDate = (date, dateFormat) => {
   if (!date) return ''
   if (dateFormat) return format(parseISO(date), dateFormat, { locale: ru })
   return format(parseISO(date), 'dd.MM.yy / H:mm')
}

export const formatParsedDate = (date, dateFormat) => {
   if (!date) return ''
   return format(date, dateFormat || 'dd.MM.yy / H:mm')
}

export const getDifferenceInMinutes = (date) => {
   const diff = Math.abs(new Date() - parseISO(date))
   return Math.floor(diff / 1000 / 60)
}

export const getDateTimeDifferenceInMinutes = (dateA, dateB) => {
   const diff = Math.abs(dateA - dateB)
   return Math.floor(diff / 1000 / 60)
}
