import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   isLoading: false,
   orders: [],
   page: 0,
   totalPage: 1,
   statisticsOfOrders: {
      allAmount: 0,
      averageAmount: 0,
      quantityOfOrders: 0,
      quantityOfOrdersByDeliverType: {
         CITY: 0,
         CITY_EXPRESS: 0,
         OUT_OF_CITY: 0,
         REGION: 0,
      },
   },
}

const hostOrdersSlice = createSlice({
   name: 'hostOrders',
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      getOrders(state, { payload }) {
         state.isLoading = false
         state.totalPage = payload.orders.allCompletedOrders.totalPage || 1
         state.orders = payload.orders.allCompletedOrders.content
         state.statisticsOfOrders = payload.orders.statisticsOfOrders
         state.page = payload.page
      },
   },
})

export const hostOrdersAction = hostOrdersSlice.actions
export default hostOrdersSlice
