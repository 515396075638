import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getOrderByIdRequest } from '../../../../api/orderService'
import useEnqueueSnackbar from '../../../../components/UI/Notification/useEnqueueSnackbar'
import {
   isOrderFromClient,
   isOrderTypeRegion,
} from '../../../../utils/constants/general'
import EditRegionClient from './EditRegionClient'
import EditRegionMaster from './EditRegionMaster'
import EditWork from './EditWork'

export default function Index() {
   const { orderId } = useParams()
   const [order, setOrder] = useState('')
   const notify = useEnqueueSnackbar()

   useEffect(() => getOrderById(), [])

   const getOrderById = async () => {
      try {
         const { data: order } = await getOrderByIdRequest(orderId)
         if (isOrderTypeRegion(order.type)) {
            if (isOrderFromClient(order.orderStatus))
               return setOrder(<EditRegionClient />)
            return setOrder(<EditRegionMaster />)
         }
         return setOrder(<EditWork />)
      } catch (error) {
         return notify('Failed to obtain order information', 'error')
      }
   }

   return order
}
