import styled from 'styled-components'
import Button from './Button'
import Input from './Input'
import Select from './Select'

// order inner start

// @OrderInner, EditWork, EditOrder, EditRegionClient
export const Card = styled.div`
   margin-bottom: 12px;
   min-height: 270px;
   background: rgb(255, 255, 255);
   border: 1px solid #d4d4d4;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
   border-radius: 10px;
   padding: 40px 60px 40px 60px;
`

// @OrderInner, EditOrder, EditWork
export const ContentBlock = styled.div`
   display: flex;
   justify-content: space-between;

   @media (max-width: 500px) {
      flex-direction: column;
   }
`

// @OrderInner, EditOrder, EditWork
export const CardTitle = styled.div`
   font-size: 18px;
   line-height: 25px;
   color: #a2a2a2;
   margin-bottom: 24px;
`

export const CardInfo = styled.div`
   display: flex;
   align-items: flex-start;
   font-size: 18px;
   line-height: 25px;
   width: 202px;

   & span {
      margin: 10px;
      margin-right: 0px;
   }
`

export const PayBlock = styled.div`
   ${CardTitle} {
      margin: 0;
      margin-top: 10px;
   }

   ${CardInfo} {
      margin: 0;
      margin-top: 15px;
      width: auto;
   }
`

// @OrderInner, EditOrder, EditWork
export const Actions = styled.div`
   margin-top: 40px;
   justify-content: flex-end;
   display: flex;
   gap: 20px;
   margin-bottom: 20px;
`

// @EditWord, EditRegionClient
export const StyledComment = styled.div`
   word-wrap: break-word;
`

export const RightWrapper = styled.div`
   margin-left: auto;
   width: 350px;
`

// @OrderInner, EditOrder, EditWork, EditRegionClient
export const OrderNums = styled.div`
   font-weight: 400;
   font-size: 16px;
   line-height: 22px;
   margin: 12px 0px;
   span {
      font-weight: 600;
      color: #1f6ed4;
   }
   ${({ last }) => last && { 'margin-bottom': '60px' }}
`

// order inner end

// orderCard start

export const OrderCardContainer = styled.div`
   position: relative;
   width: 100%;
   background: ${({ background }) => background};
   border: 1px solid #d4d4d4;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
   border-radius: 10px;
   padding: 25px;
   padding-bottom: 0px;
   border: ${({ border }) => border};
   cursor: pointer;
`

export const MeatBallContainer = styled.div`
   position: absolute;
   bottom: 15px;
   right: 10px;
`

export const Header = styled.div`
   height: ${({ height }) => height || 40}px;

   @media (max-width: 500px) {
      height: auto;
      margin-bottom: 12px;
   }
`

export const HeaderTitle = styled.p`
   font-weight: 400;
   font-size: 20px;
   line-height: 27px;
   color: #000000;
   margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`

export const OrderNumber = styled.span`
   font-size: 20px;
   margin-left: 10px;
   color: #1f6ed4;
`

export const HeaderContent = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
`

export const StatusContainer = styled.div`
   display: flex;
   align-items: center;
`

export const ContentItem = styled.div`
   flex-basis: 90%;
   margin: 0px 10px;
   margin-bottom: 15px;

   @media (max-width: 500px) {
      margin-left: 0;
   }
`

export const ContentTitle = styled.div`
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 25px;
   color: #a2a2a2;
   padding-bottom: 15px;
   margin-left: 2px;
`

export const OrderInfo = styled.div`
   display: flex;
   align-items: flex-start;
   font-size: 18px;
   line-height: 25px;
   color: #000000;
   margin-left: -5px;
   & span {
      margin: 10px;
      margin-right: 0px;
   }
`

export const OrderCardDivider = styled.div`
   border: 1px solid #ececec;
   box-sizing: border-box;
   box-shadow: 0px 6px 12px rgba(36, 36, 36, 0.08);
   transform: rotate(180deg);
   height: 160px;
   margin-top: 15px;

   @media (max-width: 500px) {
      display: none;
   }
`

// orderCard end

// Order Edit start

export const DriverCommentInput = styled(Input)`
   .MuiInputBase-formControl {
      margin-top: 5px;
      height: auto;
   }
`

export const ContentInner = styled.div`
   width: 100%;
   margin: 15px;
   margin-right: 25px;
`

export const CardInfoEdit = styled.div`
   font-size: 18px;
   line-height: 25px;
   display: flex;
   align-items: center;
   .container {
      margin: 0px;
      padding: 5px 0 5px 0;
      legend {
         span {
            margin: 0px;
         }
      }
   }
   & span {
      margin: 15px 10px 15px 5px;
   }
`

export const PayerBlock = styled(ContentInner)`
   ${CardInfoEdit} {
      span {
         margin: 0;
      }
   }
`

export const StyledSelect = styled(({ className, ...props }) => (
   <StyledSelectRoot {...props} MenuProps={{ paper: className }} />
))``

const StyledSelectRoot = styled(Select)`
   padding: 0px !important;
`

// OrderCards
export const StatusButton = styled(Button)`
   gap: 10px;
   padding: 6px 15px;
   margin-top: -2px;
   border: 1px solid #d4d4d4;
   border-radius: 6px;
   color: ${({ btnColor }) => btnColor};
   :hover {
      background-color: transparent;
   }

   font-family: 'Nunito';
   font-style: normal;
   font-weight: 400;
   font-size: 18px;
   line-height: 25px;
`

export const Flexer = styled('div')`
   display: flex;
   span {
      margin-top: 0;
      margin-bottom: 0;
   }
   gap: 5px;
   align-items: center;
`
