import { createSlice } from '@reduxjs/toolkit'

const masterState = {
   isLoading: false,
   masters: [],
   officeUserData: {},
}

const masterSlice = createSlice({
   name: 'master',
   initialState: masterState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      getMasters(state, { payload }) {
         state.isLoading = false
         state.masters = payload.masters
      },
      createMaster(state) {
         state.isLoading = false
      },
      deleteMaster(state, { payload }) {
         state.isLoading = false
         state.masters = state.masters.filter(
            (master) => master.id !== payload.id
         )
      },
      getOfficeUserData(state, { payload }) {
         state.isLoading = false
         state.officeUserData = payload.officeUserData
      },
   },
})
export const masterActions = masterSlice.actions
export default masterSlice
