import axiosInstance from '../config/axios-instance'

export const getRegionsRequest = () => {
   return axiosInstance.get(`/regions`)
}

export const getBranchRegionsRequest = () => {
   return axiosInstance.get(`/regions`)
}

export const getExistingRegionByIdRequest = (id) => {
   return axiosInstance.get(`/regions/${id}`)
}

export const editExistingReqionRequest = (regionData) => {
   return axiosInstance.put('/regions', regionData)
}

export const createReqionRequest = (regionData) => {
   return axiosInstance.post('/regions', regionData)
}

export const deleteRegionRequest = (regionId) => {
   return axiosInstance.delete(`/regions/${regionId}`)
}
