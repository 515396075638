import { CircularProgress, Box } from '@mui/material'
import PropTypes from 'prop-types'

const ButtonSpinner = (props) => {
   const { size, ...otherProps } = props
   return (
      <Box {...otherProps}>
         <CircularProgress size={size} />
      </Box>
   )
}

ButtonSpinner.propTypes = {
   size: PropTypes.number.isRequired,
}

export default ButtonSpinner
