import React from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import ru from 'date-fns/locale/ru'
import App from './App'
import reportWebVitals from './reportWebVitals'
import index from './store'

ReactDOM.render(
   <React.StrictMode>
      <SnackbarProvider maxSnack={5} autoHideDuration={3000}>
         <Provider store={index}>
            <Router>
               <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
                  <App />
               </LocalizationProvider>
            </Router>
         </Provider>
      </SnackbarProvider>
   </React.StrictMode>,
   document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
