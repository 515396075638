import { getClientsByUsernameRequest } from '../../api/clientService'
import {
   cancelOrderRequest,
   createOrderRequest,
   deliverOrderRequest,
   editOrderRequest,
   getOrdersRequest,
} from '../../api/orderService'
import { orderActions } from './orderSlice'
import { getErrorMessage } from '../../utils/helpers/general'

export const createOrder = (orderData, notify, closeModalAfterRequest) => {
   return async (dispatch) => {
      try {
         dispatch(
            orderActions.setCreateLoading({
               isLoading: true,
            })
         )
         await createOrderRequest(orderData)
         dispatch(getOrders(notify))
         notify('Order successfully created', 'success')
         closeModalAfterRequest()
      } catch (error) {
         dispatch(
            orderActions.setCreateLoading({
               isLoading: false,
            })
         )
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const getOrders = (notify) => {
   return async (dispatch) => {
      try {
         dispatch(orderActions.setLoading({ isLoading: true }))
         const { data: orders } = await getOrdersRequest()
         dispatch(orderActions.getOrders({ orders }))
      } catch (error) {
         dispatch(orderActions.setLoading({ isLoading: false }))
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const getClients = (notify, username) => {
   return async (dispatch) => {
      try {
         const response = await getClientsByUsernameRequest(username)
         const clients = response.data
         dispatch(
            orderActions.getClients({
               clients,
            })
         )
      } catch (error) {
         dispatch(orderActions.setClientsLoading({ isLoading: false }))
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
      }
   }
}

export const editOrder = (id, orderData, notify, navigateToOrder) => {
   return async (dispatch) => {
      try {
         dispatch(orderActions.setLoading({ isLoading: true }))
         const {
            barcode,
            orderStatus,
            firstDriver,
            secondDriver,
            check,
            hasDrivers,
            ...otherData
         } = orderData

         await editOrderRequest(id, otherData)
         dispatch(getOrders(notify))
         notify('Order successfully changed', 'success')
         return navigateToOrder && navigateToOrder()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         return dispatch(
            orderActions.setLoading({
               isLoading: false,
            })
         )
      }
   }
}

export const cancelOrder = (id, notify, closeCancelModal, navigateToOrders) => {
   return async (dispatch) => {
      try {
         dispatch(orderActions.setLoading({ isLoading: true }))
         await cancelOrderRequest(id)
         dispatch(orderActions.cancelOrder({ id }))
         notify('Order cancelled', 'success')
         closeCancelModal()
         return navigateToOrders && navigateToOrders()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         dispatch(
            orderActions.setLoading({
               isLoading: false,
            })
         )
         return closeCancelModal()
      }
   }
}

export const deliverOrder = (id, orderData, navigateToOrders, notify) => {
   return async (dispatch) => {
      try {
         dispatch(orderActions.setLoading({ isLoading: true }))
         await deliverOrderRequest(id, orderData)
         dispatch(getOrders(notify))
         return navigateToOrders && navigateToOrders()
      } catch (error) {
         notify(`Something went wrong ${getErrorMessage(error)}`, 'error')
         return dispatch(orderActions.setLoading({ isLoading: false }))
      }
   }
}
