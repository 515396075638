import React from 'react'
import styled from 'styled-components'

import logoIcon from '../../assets/icons/Supplysync.svg'
import mainImage from '../../assets/images/MainImg.png'
import LogInForm from '../../components/LoginForm/LoginForm'

const MainBlock = styled.div`
   position: relative;
   width: 100%;
   padding: 30px 0 0 64px;
   display: flex;

   @media (max-width: 500px) {
      padding: 10px;
      align-items: center;
      height: 100vh;
   }
`
const FirstBlock = styled.div`
   width: 50%;
   display: flex;
   justify-content: center;
   flex-direction: column;

   @media (min-width: 1400px) {
      width: 70%;
   }

   @media (max-width: 500px) {
      display: none;
   }
`

const SecondBlock = styled.div`
   width: 50%;
   margin-top: 116px;
   align-self: center;

   @media (min-width: 1400px) {
      width: 50%;
   }

   @media (max-width: 500px) {
      width: 100%;
      margin-top: 0;
   }
`
const LogoBlock = styled.div`
   display: flex;
   margin: 18px 0 80px;
   align-self: center;
`

const MainImage = styled.img`
   object-fit: cover;
   object-position: 100% 20px;
`

export default function LogIn() {
   return (
      <MainBlock>
         <FirstBlock>
            <LogoBlock>
               <img src={logoIcon} alt="logo" />
            </LogoBlock>
            <MainImage src={mainImage} width="98%" alt="mainImg" />
         </FirstBlock>
         <SecondBlock>
            <LogInForm />
         </SecondBlock>
      </MainBlock>
   )
}
