import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   isLoading: false,
   entities: [],
   orders: [],
   statistics: {},
}

const juridicalEntitySlice = createSlice({
   name: 'juridicalEntity',
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      setEntities(state, { payload }) {
         state.entities = payload
      },
      setReport(state, { payload }) {
         state.orders = payload.orders
         state.statistics = payload.statistics
      },
   },
})
export const juridicalEntityActions = juridicalEntitySlice.actions
export default juridicalEntitySlice
