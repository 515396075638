import React from 'react'
import styled from 'styled-components'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'

const MainContainer = styled.div`
   position: relative;

   .link-wrapper {
      #active {
         path {
            fill: #1f6ed4;
         }
         background: #eaebf5;
         border-radius: 10px;
         cursor: pointer;
         transition: all 0.3s linear;
         color: #1f6ed4;
         &::before {
            content: '';
            position: absolute;
            left: -26px;
            width: 10px;
            height: 50px;
            background: #1f6ed4;
            border-radius: 0px 10px 10px 0px;
            margin: 0;
            padding: 0;
            margin-right: 20px;
            transition: all 0.3s linear;
         }
      }
   }
`

const StyledMenu = styled(ListItem)`
   position: relative;
   margin-left: 10px;
   margin-bottom: 8px;
   padding-top: 10px;
   display: flex;
   align-items: center;
   justify-content: flex-start;

   &:hover {
      background: #eaebf5;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s linear;
   }
`
const StyledLink = styled(NavLink)`
   display: flex;
   color: inherit;
   text-decoration: none;
`

const StyledListItemText = styled(ListItemText)`
   font-style: normal;
   font-weight: bolder;
   font-size: 20px;
   line-height: 25px;
   color: inherit;
   padding-left: 10px;
   .dMcQmE {
      font-weight: 600;
   }
`

const Icon = styled.svg``

const MenuItem = ({ el, onClick }) => {
   const { pathname } = useLocation()

   const isLinkActive = () => {
      return pathname.startsWith(`/dashboard/${el.path}`) ? 'active' : null
   }

   return (
      <MainContainer onClick={onClick}>
         <div className="link-wrapper">
            <StyledLink end to={el.path}>
               <StyledMenu id={isLinkActive()}>
                  <Icon as={el.icon} />
                  <StyledListItemText primary={el.text} />
               </StyledMenu>
            </StyledLink>
         </div>
      </MainContainer>
   )
}
MenuItem.propTypes = {
   el: PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.shape({}).isRequired,
      path: PropTypes.string.isRequired,
   }).isRequired,
   onClick: PropTypes.func,
}

MenuItem.defaultProps = {
   onClick: () => undefined,
}

export default MenuItem
