import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ROUTES } from '../utils/constants/general'

function PrivateRouteProtectedByRole({
   component: RouteComponent,
   roles,
   fallbackPath,
}) {
   const isAuthenticated = useSelector((state) => state.user.isAuthorized)
   const currentUserRole = useSelector((state) => state.user.role)

   if (!isAuthenticated) {
      return <Navigate to={ROUTES.LOGIN} replace />
   }

   if (isAuthenticated && !roles.includes(currentUserRole)) {
      return <Navigate to={fallbackPath} replace />
   }

   return RouteComponent
}

PrivateRouteProtectedByRole.propTypes = {
   component: PropTypes.element,
   roles: PropTypes.arrayOf(PropTypes.string),
   fallbackPath: PropTypes.string,
}

PrivateRouteProtectedByRole.defaultProps = {
   component: PropTypes.element,
   roles: PropTypes.arrayOf(PropTypes.string),
   fallbackPath: ROUTES.DASHBOARD,
}

export default PrivateRouteProtectedByRole
