import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   orders: [],
   clients: [],
   isLoading: false,
   isCreateLoading: false,
   isEachClientLoading: false,
}

const orderSlice = createSlice({
   name: 'orderSlice',
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
      setCreateLoading(state, { payload }) {
         state.isCreateLoading = payload.isLoading
      },
      getOrders(state, { payload }) {
         state.isLoading = false
         state.isCreateLoading = false
         state.orders = payload.orders
      },
      getTariffs(state, { payload }) {
         state.isLoading = false
         state.tariffs = payload.tariffs
      },
      getClients(state, { payload }) {
         state.isEachClientLoading = false
         state.clients = payload.clients
      },
      setClientsLoading(state, { payload }) {
         state.isEachClientLoading = payload.isLoading
      },
      cancelOrder(state, { payload }) {
         state.isLoading = false
         state.orders = state.orders.filter((order) => order.id !== payload.id)
      },
   },
})

export const orderActions = orderSlice.actions

export default orderSlice
