import { configureStore } from '@reduxjs/toolkit'
import companySlice from './company/companySlice'
import masterSlice from './master/masterSlice'
import userSlice from './user/userSlice'
import branchSlice from './branch/branchSlice'
import tariffSlice from './tariff/tariffSlice'
import orderSlice from './order/orderSlice'
import courierSlice from './courier/courierSlice'
import regionSlice from './region/regionSlice'
import workSlice from './work/workSlice'
import hostOrdersSlice from './hostOrders/hostOrdersSlice'
import citySlice from './city/citySlice'
import juridicalEntitySlice from './juridicalEntity/juridicalEntitySlice'

const index = configureStore({
   reducer: {
      company: companySlice.reducer,
      user: userSlice.reducer,
      master: masterSlice.reducer,
      branch: branchSlice.reducer,
      tariff: tariffSlice.reducer,
      order: orderSlice.reducer,
      courier: courierSlice.reducer,
      region: regionSlice.reducer,
      work: workSlice.reducer,
      hostOrders: hostOrdersSlice.reducer,
      city: citySlice.reducer,
      juridicalEntity: juridicalEntitySlice.reducer,
   },
})

export default index
