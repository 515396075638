import Box from '@mui/material/Box'
import List from '@mui/material/List'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as MainLogo } from '../../assets/icons/Supplysync.svg'

import { MENU_ITEMS } from '../../utils/constants/general'
import MenuItem from './MenuItems'

const StyledBox = styled(Box)`
   width: 230px;
   background: #f5f6ff;
   //position: fixed;
   //width: 17%;
`
const StyledList = styled(List)`
   padding: 12px 20px 15px 16px;
   &:first-child {
      padding-top: 67px;
   }
`

const Wrapper = styled.div`
   height: 100vh;
`

const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 180px;
   height: 75px;
   border-bottom: 1.5px solid #ececec;
   margin-left: 30px;
   margin-bottom: 21px;
   padding-right: 20px;
   margin-right: 20px;
   & img {
      margin-left: 10px;
   }
`

const SideDrawerMenu = ({ role }) => {
   const navigate = useNavigate()
   return (
      <StyledBox>
         <Wrapper>
            <Container
               style={{ cursor: 'pointer' }}
               onClick={() => navigate('/')}
            >
               <MainLogo />
            </Container>

            <StyledList>
               {MENU_ITEMS[role].length > 0 &&
                  MENU_ITEMS[role] &&
                  MENU_ITEMS[role].map((el) => (
                     <MenuItem el={el} key={el.path} />
                  ))}
            </StyledList>
         </Wrapper>
      </StyledBox>
   )
}

SideDrawerMenu.propTypes = {
   role: PropTypes.string,
}

SideDrawerMenu.defaultProps = {
   role: '',
}
export default SideDrawerMenu
