import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { logIn } from '../../store/user/userActions'
import Modal from '../UI/Modal'
import { ReactComponent as Blocked } from '../../assets/images/blocked.svg'

const SignInBlock = styled.div`
   max-width: 440px;
   background-color: #d1e5fe;
   padding: 50px 35px;
   border-radius: 10px;
   font-size: 16px;
`
const HeadTitle = styled.h1`
   text-align: center;
   font-size: 28px;
   margin-bottom: 40px;
`

const InputBlock = styled.div`
   color: #8e8e8e;
`

const Inputs = styled.input`
   width: 100%;
   background-color: white;
   padding: 12px 22px;
   border: none;
   border-radius: 10px;
   margin: 12px 0 22px;
   outline: none;
`

const ErrorMessage = styled.p`
   color: red;
   text-align: center;
`

const ButtonSignIn = styled.button`
   background-color: #1f6ed4;
   width: 230px;
   text-align: center;
   border-radius: 10px;
   padding: 15px 0;
   font-size: 24px;
   color: white;
   margin: 16px 0 45px 21%;
   border: none;
   :hover {
      background-color: #225ba4;
   }
   :active {
      background-color: #5c9df0;
   }

   @media (max-width: 500px) {
      width: 100%;
      margin: 0;
   }
`
const StyledInput = styled(Inputs)`
   font-size: 16px;
`

export default function LogInForm() {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { loginError } = useSelector((state) => state.user)

   const [isBanned, setIsBanned] = useState(false)

   const [userData, setUserData] = useState({
      email: '',
      password: '',
   })

   const handleChange = (val) => {
      if (val.target.name === 'email') {
         setUserData({ ...userData, email: val.target.value })
      } else if (val.target.name === 'password') {
         setUserData({ ...userData, password: val.target.value })
      }
   }
   const navigateToCompaniesPage = () => {
      return navigate('/dashboard/companies')
   }
   const handleSubmit = (e) => {
      e.preventDefault()
      dispatch(
         logIn(
            userData.email,
            userData.password,
            navigateToCompaniesPage,
            setIsBanned
         )
      )
   }

   return (
      <>
         {isBanned && (
            <StyledModal open={isBanned} onClose={() => setIsBanned(false)}>
               <Blocked />
               <h2>Your account is locked</h2>
               <p>For more information, contact your system administrator</p>
            </StyledModal>
         )}
         <SignInBlock>
            <HeadTitle>Welcome !</HeadTitle>
            <form onSubmit={handleSubmit}>
               <InputBlock>
                  <label htmlFor="input">Login</label>
                  <StyledInput
                     placeholder="Enter login"
                     id="input"
                     type="text"
                     name="email"
                     value={userData.email}
                     onChange={handleChange}
                  />
               </InputBlock>
               <InputBlock>
                  <label htmlFor="password">Password</label>
                  <StyledInput
                     placeholder="Enter password"
                     id="password"
                     type="password"
                     name="password"
                     value={userData.password}
                     onChange={handleChange}
                  />
               </InputBlock>
               {loginError && (
                  <ErrorMessage>Incorrect login and/or password.</ErrorMessage>
               )}
               <ButtonSignIn type="submit">Sign in</ButtonSignIn>
            </form>
         </SignInBlock>
      </>
   )
}
const StyledModal = styled(Modal)`
   .MuiBox-root {
      width: 430px;
      padding: 20px 15px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
         margin-top: 10px;
         color: #3c3c4399;
         line-height: 26px;
         font-size: 18.9182px;
      }
   }
`
