import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

function Spinner(props) {
   const { size } = props
   return (
      <Box
         sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}
         {...props}
      >
         <CircularProgress size={size} />
      </Box>
   )
}

Spinner.propTypes = {
   size: PropTypes.number.isRequired,
}

export default Spinner
