import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

import useEnqueueSnackbar from '../../../../components/UI/Notification/useEnqueueSnackbar'
import { getOrderByIdRequest } from '../../../../api/orderService'
import { formatDate } from '../../../../utils/helpers/dateTimeHelpers'
import { deliverOrder, editOrder } from '../../../../store/order/orderActions'
import { getOnlineCouriers } from '../../../../store/courier/courierAction'
import { getMasterTariffs } from '../../../../store/tariff/tariffActions'
import { getRegions } from '../../../../store/region/regionActions'
import { DriverCard } from '../DriverCard'

import Button from '../../../../components/UI/Button'
import CustomBreadcrumbs from '../../../../components/UI/Breadcrumbs'
import {
   convertDriverNumbers,
   getCouriers,
   getDriver,
   getDriverStatus,
   getRecipientsAddress,
   IS_MASTER_CAN_EDIT_ORDER,
   ORDER_PLACEHOLDER,
   ORDER_STATUSES,
} from '../../../../utils/constants/general'
import {
   MASTER_EDITABLE_STATUSES,
   REGION_EDITABLE_STATUSES,
} from '../../../../utils/constants/order-constants'
import { Actions, OrderNums } from '../../../../components/UI/OrderUI'
import StatusSelect from '../../../../components/UI/StatusSelect/StatusSelect'
import EditWorkRegionForm from './EditWorkRegionForm'

export default function EditRegionClient() {
   const [order, setOrder] = useState({ ...ORDER_PLACEHOLDER })

   const couriers = useSelector(getCouriers)

   const { orderId } = useParams()
   const notify = useEnqueueSnackbar()
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const getOrderById = async () => {
      try {
         const { data } = await getOrderByIdRequest(orderId)
         const recipientsNumbers = convertDriverNumbers(data.recipientsNumbers)
         const sendersNumbers = convertDriverNumbers(data.sendersNumbers)

         dispatch(getOnlineCouriers(notify, orderId))

         const isMasterCanEdit = (order) => {
            if (order.masterIndex === 'FIRST') {
               if (MASTER_EDITABLE_STATUSES.includes(order.status)) {
                  return { data: true }
               }
            }
            return { data: false }
         }
         const canEdit = isMasterCanEdit(data)
         if (canEdit.data) {
            dispatch(getRegions(notify))
            dispatch(getMasterTariffs(notify))
         }

         setOrder({
            ...data,
            hasDrivers: {
               firstDriver: Boolean(data.drivers.first),
               secondDriver: Boolean(data.drivers.second),
            },
            check: {
               firstDriver: getDriver(data.drivers.first),
               secondDriver: getDriver(data.drivers.second),
            },
            firstDriver: getDriver(data.drivers.first),
            secondDriver: getDriver(data.drivers.second),
            recipientsAddress: data.recipientsAddress || '',
            tariffId: data.tariff.id,
            regionName: data.regionB.region,
            regionId: data.regionB.id,
            recipientsNumbers,
            sendersNumbers,
            createDate: formatDate(data.createDate),
            fullAddress: getRecipientsAddress(data),
            canEdit: isMasterCanEdit(data),
            cityId: data.city.id,
            currentStatus: data.status,
         })
      } catch (error) {
         notify('Failed to get order data', 'error')
      }
   }
   useEffect(() => getOrderById(), [])

   const handleCourierChange = ({ target: { name, value } }) => {
      const { car, ...other } = couriers.find(({ id }) => id === value)

      const updatedState = {
         ...order,
         [name]: {
            ...other,
            regionName: order.firstDriver.regionName,
            car: car ? 'Yes' : 'No',
            driverChangeStatus: 'CHANGED',
         },
      }
      setOrder(updatedState)
   }

   const handleStatusChange = ({ target: { name, value } }) => {
      setOrder({ ...order, [name]: value })
   }

   const navigateToOrder = () => navigate(-1)
   const formSubmitHandler = (e) => {
      e.preventDefault()

      const updated = {
         ...order,
         sendersNumbers: order.sendersNumbers.map(({ num }) => num),
         recipientsNumbers: order.recipientsNumbers.map(({ num }) => num),
         payer: order.payer === 'true',
         drivers: {
            first: getDriverStatus(order, 'firstDriver'),
            second: getDriverStatus(order, 'secondDriver'),
         },
      }

      if (order.currentStatus === 'DELIVERED') {
         return dispatch(
            deliverOrder(order.id, updated, navigateToOrder, notify)
         )
      }

      return dispatch(editOrder(orderId, updated, notify, navigateToOrder))
   }

   const showSecondCourier = () => order.masterIndex !== 'FIRST'

   const firstCourierEditable = () => {
      if (order.masterIndex === 'FIRST') {
         return !IS_MASTER_CAN_EDIT_ORDER.includes(order.status)
      }
      return false
   }

   const secondCourierEditable = () => {
      if (
         order.masterIndex !== 'FIRST' &&
         REGION_EDITABLE_STATUSES.includes(order.status)
      ) {
         return true
      }
      return false
   }

   const paths = {
      works: 'Works',
      edit: 'Edit',
      [orderId]: `#${orderId}`,
   }

   return (
      <form onSubmit={formSubmitHandler}>
         <CustomBreadcrumbs pathTranslate={paths} />
         <Header>
            <div>
               <OrderNums>
                  Order number: <span>#{order.id}</span>
               </OrderNums>
               <OrderNums>
                  Barcode number:
                  <span>{order.barcode || '-------'}</span>
               </OrderNums>
            </div>
            <div>
               <StatusSelect
                  options={[
                     {
                        label: ORDER_STATUSES[order.status]?.text,
                        id: order.status,
                     },
                     { label: 'Delivered', id: 'DELIVERED' },
                  ]}
                  name="currentStatus"
                  onChange={handleStatusChange}
                  value={order.currentStatus}
               />
            </div>
         </Header>
         <EditWorkRegionForm
            order={order}
            onChange={setOrder}
            canEdit={order.canEdit}
         />
         <DriverCard
            region={order.firstDriver.regionName || ''}
            driver={order.firstDriver}
            onChange={setOrder}
            comment={order.commentDriver || ''}
            couriers={couriers}
            onCourierChange={handleCourierChange}
            editable={firstCourierEditable()}
            name="firstDriver"
         />
         {showSecondCourier() && (
            <DriverCard
               region={order.secondDriver.regionName || ''}
               driver={order.secondDriver}
               onChange={setOrder}
               couriers={couriers}
               onCourierChange={handleCourierChange}
               name="secondDriver"
               editable={secondCourierEditable()}
            />
         )}
         <Actions>
            <Button variant="outlined" onClick={navigateToOrder}>
               Cancel
            </Button>
            <Button variant="contained" type="submit">
               Save
            </Button>
         </Actions>
      </form>
   )
}

const Header = styled('div')`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 60px;
`
