import { InputAdornment } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FormGroupContainer from './FormGroupContainer'
import Input from './Input'
import { ReactComponent as PlusIcon } from '../../assets/icons/plusIcon.svg'
import { ReactComponent as RemoveIcon } from '../../assets/icons/removeIcon.svg'
import PhoneInput from './PhoneInput'

const DynamicPhoneInput = ({
   onChange,
   order,
   data,
   name,
   addSecondNum,
   removeSecondNum,
   error,
   className,
}) => {
   const { num, id } = data
   return (
      <FormGroupContainer className="container">
         <StyledInput
            required
            className={className}
            InputProps={{
               inputComponent: StyledInputMask,
               value: num,
               name,
               error,
               onChange: (e) => onChange(e, order),
               endAdornment: (
                  <InputAdornment position="end">
                     {order ? (
                        <RemoveIcon
                           cursor="pointer"
                           onClick={removeSecondNum(name, id)}
                        />
                     ) : (
                        <PlusIcon
                           cursor="pointer"
                           onClick={addSecondNum(name)}
                        />
                     )}
                  </InputAdornment>
               ),
            }}
         />
      </FormGroupContainer>
   )
}

DynamicPhoneInput.propTypes = {
   onChange: PropTypes.func.isRequired,
   order: PropTypes.number.isRequired,
   name: PropTypes.string.isRequired,
   className: PropTypes.string,
   error: PropTypes.bool,
   data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      num: PropTypes.string.isRequired,
   }).isRequired,
   addSecondNum: PropTypes.func.isRequired,
   removeSecondNum: PropTypes.func.isRequired,
}

DynamicPhoneInput.defaultProps = {
   className: '',
   error: false,
}

const StyledInput = styled(Input)`
   fieldset {
      top: -11px !important;
   }

   .Mui-error {
      fieldset {
         border-color: unset !important;
         background-color: rgb(250, 231, 231);
         opacity: 0.5;
      }
   }
   .Mui-focused {
      fieldset {
         border-width: 1px !important;
      }
   }
`
const StyledInputMask = styled(PhoneInput)`
   height: auto;
   border: none;
   padding: 10px 8px;
`

export default DynamicPhoneInput
