import React, { useMemo } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'
import { Radio as MuiRadio, Select as MuiSelect } from '@mui/material'
import styled from 'styled-components'
import InputLabel from '@mui/material/InputLabel'
import { ReactComponent as DropDownIcon } from '../../../assets/icons/Arrows.svg'
import { WEIGHT_LABELS } from '../../../utils/constants/general'

const WeightSelect = (props) => {
   const {
      label,
      options,
      value,
      onChange,
      getOptionLabel,
      getOptionValue,
      getOptionId,
      allCompanies,
      onClick,
      inputLabelProps,
      ...otherProps
   } = props
   const frequencyCounter = useMemo(
      () =>
         options.reduce((i, a) => {
            return { ...i, [a.id]: a.label }
         }, {}),
      []
   )
   return (
      <StyledFormControl>
         <StyledInputLabel sx={inputLabelProps} autoComplete="off">
            {label}
         </StyledInputLabel>

         <StyledSelect
            MenuProps={{ disablePortal: true }}
            label={label}
            labelId="demo-simple-select-autowidth-label"
            onChange={onChange}
            IconComponent={DropDownIcon}
            renderValue={(selected) =>
               `${frequencyCounter[selected] || WEIGHT_LABELS[selected]}`
            }
            value={value}
            {...otherProps}
         >
            <Title>Select the weight of the parcel</Title>
            {options.map((option, i) => (
               <StyledMenuItem
                  key={getOptionId ? getOptionId(option) : option.id}
                  value={getOptionValue ? getOptionValue(option) : option.id}
               >
                  <Radio checked={getOptionValue(option) === value} />
                  <OptionContent last={options.length - 1 === i}>
                     {getOptionLabel ? getOptionLabel(option) : option.label}
                     <span>{option.price}</span>
                  </OptionContent>
               </StyledMenuItem>
            ))}
         </StyledSelect>
      </StyledFormControl>
   )
}
export default WeightSelect

WeightSelect.propTypes = {
   label: PropTypes.string,
   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   onChange: PropTypes.func.isRequired,
   options: PropTypes.arrayOf(
      PropTypes.shape({
         label: PropTypes.string,
         value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
   ).isRequired,
   getOptionLabel: PropTypes.func,
   getOptionValue: PropTypes.func,
   getOptionId: PropTypes.func,
   onClick: PropTypes.func,
   allCompanies: PropTypes.string,
   inputLabelProps: PropTypes.shape({}),
}

WeightSelect.defaultProps = {
   label: null,
   inputLabelProps: null,
   getOptionId: null,
   getOptionLabel: null,
   getOptionValue: null,
   onClick: null,
   value: '',
   allCompanies: null,
}

const StyledFormControl = styled(FormControl)`
   width: 100%;
`

export const StyledInputLabel = styled(InputLabel)`
   &.MuiInputLabel-formControl {
      transform: translate(24px, 10px);
      color: #9c9b9b;
      &.MuiInputLabel-shrink {
         &.Mui-focused {
            color: #232323 !important;
         }
         transform: translate(15px, -10px);
         font-size: 13px;
         color: rgba(0, 0, 0, 0.6) !important;
         line-height: 19px;
      }
   }
`
const StyledMenuItem = styled(MenuItem)`
   &.MuiMenuItem-root {
      font-weight: 600;
      background: white;
      width: 100%;
      height: 42px;
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 0px;
      padding-left: 15px;
      color: '#0000000';
      :focus,
      :hover {
         color: #1f6ed4;
         background: #eaebf5;
         line-height: 22px;
      }
   }
   .MuiTouchRipple-root {
      line-height: 22px;
   }
   width: 100%;
`
const StyledSelect = styled(MuiSelect)`
   width: 100%;
   height: 42px;
   border-bottom: none;
   color: #232323;
   .MuiSelect-outlined {
      padding: 0px !important;
   }

   MuiInputBase-input .MuiPaper-root {
      .MuiList-root {
         padding-bottom: 0;
      }
   }

   .MuiSelect-icon {
      position: absolute;
      right: 10px;
      top: 13px;
      bottom: 13px;
   }
   &.MuiOutlinedInput-root {
      &.Mui-focused fieldset,
      :hover fieldset {
         border-color: #d4d4d4;
         border-width: 1px;
         color: #9c9b9b;
      }
   }
   & legend > span {
      font-size: 13px;
      padding-left: 8px;
      padding-right: 8px;
   }
   .MuiOutlinedInput-input {
      padding: 0;
   }
   &.MuiInputBase-root {
      border-radius: 10px;
      background: #ffffff;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 0px;
   }
`

const Radio = styled(MuiRadio)`
   &.MuiRadio-root {
      padding: 0;
      margin: 0;
      margin-top: -6px;
   }
`

const OptionContent = styled('div')`
   width: 100%;
   display: flex;
   justify-content: space-between;
   height: auto;
   span {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;

      color: #989898;
   }
   padding-bottom: 10px;
   border-bottom: ${({ last }) => (last ? 'none' : '0.5px solid #c6c6c8')};
`

// DON'T TOUCH IT
// eslint-disable-next-line no-unused-vars

const Title = styled('h3')`
   margin: 20px 20px;
   margin-bottom: 10px;
`
