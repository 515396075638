import axiosInstance from '../config/axios-instance'

export const getTariffsRequest = () => {
   return axiosInstance.get(`/tariffs`)
}

export const getMasterTariffsRequest = () => {
   return axiosInstance.get(`/tariffs`)
}

export const createTariffRequest = (tariffData) => {
   return axiosInstance.post('/tariffs', tariffData)
}

export const getTariffByIdRequest = (id) => {
   return axiosInstance.get(`/tariffs/${id}`)
}

export const editTariffRequest = (id, tariffData) => {
   return axiosInstance.put(`/tariffs/${id}`, tariffData)
}

export const deleteTariffRequest = (id) => {
   return axiosInstance.delete(`/tariffs/${id}`)
}

export const blockTariffRequest = (id) => {
   return axiosInstance.put(`/tariffs/block/${id}`)
}

export const unlockTariffRequest = (id) => {
   return axiosInstance.put(`/tariffs/unBlock/${id}`)
}
