import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import DashboardRoutes from '../../routes/DashboardRoutes'
import SideDrawerMenu from '../SideDrawer/SideDrawer'
import { ROLES, ROUTES } from '../../utils/constants/general'
import Navigation from './Navigation'
import SideDrawerForCouriers from '../SideDrawerForCouriers/SideDrawerForCouriers'
import useEnqueueSnackbar from '../../components/UI/Notification/useEnqueueSnackbar'
import { getOfficeUser } from '../../store/master/masterActions'
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen'

const Container = styled.div`
   height: 100%;
   display: flex;
   gap: 20px;
`

const ContentContainer = styled.div`
   width: 100%;
   margin-left: 1%;
   margin-right: ${({ isHost }) => (isHost ? '1%' : '1%')};

   @media (max-width: 500px) {
      margin-left: 0;
      margin-right: 0;
   }
`

const Content = styled.div`
   margin-top: ${({ isHost }) => isHost || '2%'};

   @media (max-width: 500px) {
      margin: 0;
      padding: 16px;
   }
`

const LayoutOfDashboard = () => {
   const { role } = useSelector((state) => state.user)
   const { officeUserData } = useSelector((state) => state.master)
   const notify = useEnqueueSnackbar()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { pathname } = useLocation()

   const isMobileScreen = useCheckMobileScreen()

   useEffect(() => {
      if (role === ROLES.ROLE_MASTER) dispatch(getOfficeUser(notify))

      if (pathname === '/dashboard') {
         if (role === ROLES.ROLE_HOST) return navigate(ROUTES.COMPANY)

         return navigate(ROUTES.COURIERS)
      }
      return null
   }, [])

   return (
      <Container>
         {!isMobileScreen && <SideDrawerMenu role={role} />}

         <ContentContainer isHost={role === ROLES.ROLE_HOST}>
            <Navigation role={ROLES[role]} officeUserData={officeUserData} />
            <Content isHost={role === ROLES.ROLE_HOST}>
               <DashboardRoutes />
            </Content>
         </ContentContainer>
         {role && role === ROLES.ROLE_MASTER && !isMobileScreen && (
            <SideDrawerForCouriers />
         )}
      </Container>
   )
}
export default LayoutOfDashboard
