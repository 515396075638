import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   isAuthorized: false,
   accessToken: '',
   loginError: false,
   role: null,
}

const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      logIn(state, { payload }) {
         state.accessToken = payload.accessToken
         state.role = payload.role
         state.isAuthorized = true
         state.loginError = false
      },
      logInLoading(state) {
         state.isLoading = true
      },
      logOut() {
         return initialState
      },
      errorLogin(state, { payload }) {
         state.loginError = payload.loginError
      },
   },
})

export const userActions = userSlice.actions
export default userSlice
