import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Navigate, useLocation } from 'react-router-dom'
import GlobalStyle, { theme, styledTheme } from './assets/styles/GlobalStyles'
import Spinner from './components/UI/Spinner'
import AppRoutes from './routes'
import { JWT_TOKEN_KEY, ROLE } from './utils/constants/general'
import { getStoragedItem } from './utils/helpers/localStorageHelpers'
import { userActions } from './store/user/userSlice'

function App() {
   const dispatch = useDispatch()
   const location = useLocation()
   const isAuthorized = useSelector((state) => state.user.isAuthorized)

   const [isLoading, setIsLoading] = useState(true)

   useEffect(() => {
      const jwtToken = getStoragedItem(JWT_TOKEN_KEY)
      const currentRole = getStoragedItem(ROLE)

      if (Boolean(jwtToken) && Boolean(currentRole)) {
         const authorizedUserCredentials = {
            accessToken: jwtToken,
            role: currentRole,
         }

         dispatch(userActions.logIn(authorizedUserCredentials))
      }

      setIsLoading(false)
   }, [])

   if (isLoading) return <Spinner size={55} />

   if (location.pathname === '/') return <Navigate to="/login" replace />

   return (
      <MuiThemeProvider theme={theme}>
         <StyledThemeProvider theme={styledTheme}>
            <Suspense fallback={<Spinner size={55} />}>
               <GlobalStyle />
               <AppRoutes isAuthorized={isAuthorized} />
            </Suspense>
         </StyledThemeProvider>
      </MuiThemeProvider>
   )
}

export default App
