import { userActions } from './userSlice'
// eslint-disable-next-line import/no-cycle
import { loginRequest } from '../../api/authService'
import {
   addItemToStorage,
   removeItemFromStorage,
} from '../../utils/helpers/localStorageHelpers'
import { JWT_TOKEN_KEY, ROLE } from '../../utils/constants/general'

export const logIn = (
   username,
   password,
   navigateToCompaniesPage,
   setIsBanned = () => {}
) => {
   return async (dispatch) => {
      try {
         dispatch(userActions.logInLoading())
         const response = await loginRequest({ email: username, password })

         const userCredentials = {
            accessToken: response.data.jwtToken,
            role: response.data.role[0],
         }

         addItemToStorage(response.data.jwtToken, JWT_TOKEN_KEY)
         addItemToStorage(response.data.role[0], ROLE)

         dispatch(userActions.logIn(userCredentials))
         navigateToCompaniesPage()
      } catch (error) {
         const { response } = error || {}
         if (response?.status === 417) setIsBanned(true)

         dispatch(
            userActions.errorLogin({
               loginError: true,
            })
         )
      }
   }
}

export const logOut = () => {
   return (dispatch) => {
      removeItemFromStorage(JWT_TOKEN_KEY)
      dispatch(userActions.logOut())
   }
}
